import React, { Component } from "react";
// import MomentUtils from "@date-io/moment";

// ------- amchart4
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_moonrisekingdom from "@amcharts/amcharts4/themes/moonrisekingdom";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

class ChartConfig extends Component {
  constructor(props) {
    super(props);
    this.colorArray = [];
  }
  createChart = () => {
    // Use theme
    // am4core.useTheme(am4themes_moonrisekingdom);
    am4core.useTheme(am4themes_animated);

    // Create Chart
    let chart = am4core.create(this.props.chart.id, am4charts.XYChart); // here chart is the ID of a div
    chart.padding(10, 0, 0, 0);
    // chart.zoomOutButton.disabled = true;

    let X_Axis = null;
    if (this.props.chart.axisX === "date") {
      chart.dateFormatter.dateFormat = "hh:mm:ss a | MMM dd yyyy";
      X_Axis = chart.xAxes.push(new am4charts.DateAxis());
      X_Axis.renderer.minGridDistance = 80;
      X_Axis.startLocation = -0.3;
      X_Axis.endLocation = 1.3;
      X_Axis.dateFormats.setKey("millisecond", "hh:mm:ss a");
      X_Axis.periodChangeDateFormats.setKey("millisecond", "hh:mm:ss a");
      X_Axis.dateFormats.setKey("second", "hh:mm:ss a");
      X_Axis.periodChangeDateFormats.setKey("second", "hh:mm:ss a");
      X_Axis.dateFormats.setKey("minute", "hh:mm:ss a");
      X_Axis.periodChangeDateFormats.setKey(
        "minute",
        "[bold]hh:mm a | MMM dd yyyy[/]"
      );
      X_Axis.periodChangeDateFormats.setKey(
        "hour",
        "[bold]hh:mm a | MMM dd yyyy[/]"
      );
      
      // X_Axis.dateFormats.setKey("day", "[bold]MMM dd[/]");
      // X_Axis.periodChangeDateFormats.setKey("day", "[bold]MMM yyyy[/]");
      // X_Axis.periodChangeDateFormats.setKey("day", "[bold]MMM yyyy[/]");
    } else {
      X_Axis = chart.xAxes.push(new am4charts.CategoryAxis());
      X_Axis.dataFields.category = "category";
      X_Axis.renderer.grid.template.location = 0;
      // X_Axis.renderer.minGridDistance = 100;
      X_Axis.renderer.labels.template.disabled = true;
    }
    X_Axis.cursorTooltipEnabled = false;
    X_Axis.renderer.inside = true;
    if("evenColor" in this.props.chart || "oddColor" in this.props.chart){
      if(this.props.chart.evenColor || this.props.chart.evenColor){
        var pattern = new am4core.LinePattern();
        pattern.width = 10;
        pattern.height = 10;
        pattern.strokeWidth = 1;
        pattern.stroke = am4core.color("#000000");
        pattern.rotation = 45;
        X_Axis.renderer.axisFills.template.disabled = false;
        X_Axis.renderer.axisFills.template.fill = pattern;
        X_Axis.renderer.axisFills.template.fillOpacity = 0.2;
        X_Axis.fillRule = function(dataItem) {
          var date = new Date(dataItem.value);
          if (date.getDate() % 2 === 0) {
            dataItem.axisFill.visible = true;
          }
          else {
            dataItem.axisFill.visible = false;
          }
        }
      }
    }
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "top";

    // Add scrollbar
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    // if (this.props.chart.axisX !== "date") {
    //   chart.scrollbarY = new am4core.Scrollbar();
    //   chart.scrollbarY.parent = chart.rightAxesContainer;
    //   chart.scrollbarY.dy = -10;
    // }


    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        
    if(chart.yAxes.indexOf(valueAxis2) != 0){
      valueAxis2.syncWithAxis = chart.yAxes.getIndex(0);
    }
    valueAxis2.cursorTooltipEnabled = false; 
    // valueAxis2.title.text = "Market Days";
    // valueAxis2.renderer.opposite = true;
    if ("min" in this.props.chart) {
      valueAxis2.min = this.props.chart.min;
    }
    if ("max" in this.props.chart) {
      valueAxis2.max = this.props.chart.max;
    }
    // if (this.props.chart.axisX !== "date") {
    valueAxis2.min = 0;
    // }
    // Add range
    if("rangeLine" in this.props){
      if(this.props.rangeLine){
        let range = valueAxis2.axisRanges.create();
        range.value = this.props.rangeLine.value;
        let color = "color" in this.props.rangeLine? this.props.rangeLine.color: this.props.graphcolor;
        if("endValue" in this.props.rangeLine){
          range.endValue = this.props.rangeLine.endValue;
          range.label.fill = am4core.color(color);
          range.axisFill.fill = am4core.color(color);
          range.axisFill.fillOpacity = 0.2;
          range.grid.strokeOpacity = 0;
        }else{
          range.grid.stroke = am4core.color(color);
          range.grid.strokeWidth = 1;
          range.grid.strokeOpacity = 1;
          range.label.fill = range.grid.stroke;
        }
        range.label.inside = true;
        range.label.text = this.props.rangeLine.name;
        //range.label.align = "right";
        range.label.verticalCenter = "bottom";
        if("value2" in this.props.rangeLine){
          let range2 = valueAxis2.axisRanges.create();
          range2.value = this.props.rangeLine.value2;
          range2.grid.stroke = am4core.color(color);
          range2.grid.strokeWidth = 1;
          range2.grid.strokeOpacity = 1;
          range2.label.fill = range.grid.stroke;
          range2.label.inside = true;
          range2.label.text = this.props.rangeLine.name2;
          range2.label.verticalCenter = "bottom";
        }
      }
    }

    if (this.props.chart.series.length) {
      this.props.chart.series.map((noSeries, key) => {
        
        let series;

        let graphcolor;
        if (noSeries.color === null) {
          if (key !== 0) {
            graphcolor = this.rgbToHex(
              Math.floor(Math.random() * 200 + 0),
              Math.floor(Math.random() * 200 + 0),
              Math.floor(Math.random() * 200 + 0)
            );
          }
          if (this.colorArray.length) {
            this.colorArray.map((color) => {
              if (color === graphcolor) {
                graphcolor = this.rgbToHex(
                  Math.floor(Math.random() * 200 + 0),
                  Math.floor(Math.random() * 200 + 0),
                  Math.floor(Math.random() * 200 + 0)
                );
                this.colorArray.push(graphcolor);
              } else {
                this.colorArray.push(graphcolor);
              }
            });
          } else {
            this.colorArray.push(graphcolor);
          }
        } else {
          graphcolor = noSeries.color;
        }

        let gradient = new am4core.LinearGradient();
        
        if(!"lineOnly" in noSeries){
          gradient.addColor(am4core.color(graphcolor + "30"));
          gradient.addColor(am4core.color(graphcolor + "30"));
        }else{
          if(!noSeries.lineOnly){
            gradient.addColor(am4core.color(graphcolor + "30"));
            gradient.addColor(am4core.color(graphcolor + "30"));
          }else{
            gradient.addColor(am4core.color(graphcolor + "03"));
            gradient.addColor(am4core.color(graphcolor + "03"));
          }
        }
        if (noSeries.type === "binary") {
          series = chart.series.push(new am4charts.StepLineSeries());
        } else if (noSeries.type === "bar") {
          series = chart.series.push(new am4charts.ColumnSeries());
        } else if (noSeries.type === "pie") {
          series = chart.series.push(new am4charts.PieSeries());
        } else {
          series = chart.series.push(new am4charts.LineSeries());
          series.smoothing = "monotoneX";
          // series.tensionX = "50px";
        }
        series.name = noSeries.name;
        series.dataFields.valueY = noSeries.y;
        if (this.props.chart.axisX === "date") {
          series.dataFields.dateX = noSeries.x;
          series.yAxis = valueAxis2;
        } else if (this.props.chart.axisX === "category") {
          series.dataFields.categoryX = "category";
          series.xAxis = X_Axis;
        }
        series.strokeWidth = 2;
        series.stroke = am4core.color(graphcolor);
        if (this.props.chart.axisX === "date"
        ) {
          let unit = "";
          if("unit" in this.props.chart){
            unit = this.props.chart.unit
          }
          if("label" in this.props.chart){
            series.tooltipText = "{"+this.props.chart.label+"}\n[bold font-size: 20]{valueY}[/]"+unit+"\n{dateX}";
          }else{
            series.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]"+unit+"\n{dateX}";
          }
        } else {
          if (
            this.props.chart.id !== "UP_graph" &&
            this.props.chart.id !== "Cat_graph" &&
            this.props.chart.id !== "SubCat_graph" &&
            this.props.chart.id !== "DTR_Graph"
          ) {
            series.tooltipText =
              "{categoryX}\n Downtime: [bold font-size: 20]{valueY}[/]";
          } else {
            if(this.props.chart.id !== "DTR_Graph"){
              series.tooltipText =
                "{categoryX}\n Downtime: [bold font-size: 20]{valueY}[/]" +
                this.props.chart.unit;
            }else{
              if(noSeries.name === "Downtime"){
                series.tooltipText =
                  "{category}\n Downtime: [bold font-size: 20]{valueY}[/]" +
                  this.props.chart.unit;
              }else if(noSeries.name === "Counts"){
                series.tooltipText =
                  "{category}\n Reason Count: [bold font-size: 20]{valueY}[/]"
              }
            }
          }
          if ("onSelectedColumns" in this.props) {
            series.columns.template.events.on(
              "hit",
              this.columnEventListner,
              this
            );
          }
        }
        series.tooltip.zIndex = Infinity;
        series.tooltip.label.getFillFromObject = true;
        series.tooltip.label.background.fill = am4core.color(graphcolor);

        series.fillOpacity = 1;
          series.fill = gradient;

        // chart.scrollbarX.series.push(series);
        if (this.props.chart.axisX === "date") {
          let bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.radius = 3;
          bullet.circle.strokeWidth = 2;
          bullet.circle.fill = am4core.color("#fff");
          if ("onSelectedColumns" in this.props) {
            series.columns.template.events.on(
              "hit",
              this.columnEventListner,
              this
            );
            bullet.events.on("hit", this.columnEventListner, this);
          }
        } else {
          var bullet = series.bullets.push(new am4charts.LabelBullet());
          bullet.label.text = "{categoryX}";
          bullet.label.rotation = 90;
          bullet.label.truncate = false;
          bullet.label.hideOversized = false;
          bullet.label.horizontalCenter = "right";
          bullet.locationY = 1;
          bullet.dy = -10;

          if ("onSelectedColumns" in this.props) {
            bullet.events.on("hit", this.columnEventListner, this);
          }
          // chart.maskBullets = false;
        }
        if (key === 0) {
          this.series = series;
        }
        if("opposite" in noSeries){valueAxis2.renderer.opposite = noSeries.opposite;}
      });
    }
    X_Axis.renderer.labels.template.adapter.add(
      "rotation",
      function (rotation, target) {
        let dataItem = target.dataItem;
        if (
          dataItem.date &&
          dataItem.date.getTime() ==
            am4core.time
              .round(new Date(dataItem.date.getTime()), "hour")
              .getTime()
        ) {
          target.verticalCenter = "middle";
          target.horizontalCenter = "left";
          return -90;
        } else {
          target.verticalCenter = "bottom";
          target.horizontalCenter = "middle";
          return 0;
        }
      }
    );

    let scrollAxis = chart.scrollbarX.scrollbarChart.xAxes.getIndex(0);
    // scrollAxis.renderer.labels.template.disabled = true;
    this.chart = chart;
  };
  columnEventListner = (e) => {
    this.props.onSelectedColumns(e.target.dataItem.dataContext);
  };
  componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };

  rgbToHex = (r, g, b) => {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  };
  componentDidMount() {
    this.createChart();
    this.chart.data = this.props.data;
    this.primaryColor();
  }
  componentWillUnmount() {
    this.chart.dispose();
  }

  addNode = (liveData) => {
    if (liveData) {
      this.chart.addData(liveData, 1);
      this.primaryColor();
    }
  };
  primaryColor = () => {
    var color = this.props.graphcolor;
    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color(color + "15"));
    gradient.addColor(am4core.color(color + "15"));

    this.series.stroke = am4core.color(color);
    this.series.tooltip.label.background.fill = am4core.color(color);
    this.series.fill = gradient;
  };
  render() {
    return (
      <div
        id={this.props.chart.id}
        style={{
          width: "100%",
          height:
            this.props.chart.scrollbar === true
              ? "calc(100% + 18px)"
              : "calc(100% + 70px)",
        }}
      ></div>
    );
  }
}
export default ChartConfig;
