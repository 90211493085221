import React, { Component } from "react";
import { getdevicesByEventTypes } from "../shared/services/devices";
import * as moment from "moment";

import RequestInfo from "./strip/request-info";
import Description from "./weather-desc/description";
import Weather from "./weather/weather";
import Header from "../components/header/header1";
// import Footer from "../components/footer";
import { ListGroupItemHeading } from "reactstrap";
import WeatherMap from "./weather/weatherMap";
import WeatherSummary from "./weather/weatherSummary";


class WeatherContainer extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   data: null,
    //   indication: true,
    //   device_type: [
    //     "WindDirection",
    //     "WindSpeed",
    //     "Gust",
    //     "Rain",
    //     "Illuminance",
    //     "UV",
    //     "Humidity",
    //     "Temperature",
    //   ],
    // };
    this.state = {
      data: null
    }
  }
  // loadData = async () => {
  //   try {
  //     document.getElementById("root_title").innerHTML =
  //       "Linked Things - Weather";

  //     if (this.state.data == null) {
  //       const restCalls = this.state.device_type.map((type) => {
  //         return getdevicesByEventTypes(type);
  //       });
  //       let indication = !this.state.indication;
  //       await Promise.all(restCalls).then((response) => {
  //         if (response !== "Errror") {
  //           let obj = {};
  //           response.map((data) => {
  //             let device_name = data[0].deviceId.split("_");
  //             obj[device_name[2]] = {
  //               created: data[0].created,
  //               value: data[0].value,
  //             };
  //           });
  //           this.setState({
  //             data: obj,
  //             indication,
  //           });
  //         } else {
  //           console.log("Wather-Container", restCalls);
  //         }
  //       });
  //     }
  //   } catch (err) {
  //     console.log("Error In Weather Conatiner", err);
  //   }
  // };

  // refreshData = () => {
  //   try {
  //     let check = null;
  //     const restCalls = this.state.device_type.map((type) => {
  //       return getdevicesByEventTypes(type);
  //     });
  //     console.log("Refresh Data", moment().format("HH:mm:ss"));
  //     let indication = !this.state.indication;

  //     Promise.all(restCalls).then((response) => {
  //       if (restCalls !== "Errror") {
  //         let obj = {};
  //         response.map((data) => {
  //           let device_name = data[0].deviceId.split("_");
  //           obj[device_name[2]] = {
  //             created: data[0].created,
  //             value: data[0].value,
  //           };
  //         });
  //         this.setState({
  //           data: obj,
  //           indication,
  //         });
  //       } else {
  //         console.log("Wather-Container", restCalls);
  //       }
  //     });
  //   } catch (err) {
  //     console.log("Weather", err);
  //   }
  // };

  // interval = setInterval(() => {
  //   this.refreshData();
  // }, 180000);
  weatherCoords = (data) => {
    this.setState({ data })
  }
  render() {
    // this.loadData();
    return (
      <React.Fragment>
        {/* <Header /> */}
        {/* <Description /> */}
        {/* <div className="dropshadow p-3 gradient-primary1 text-light h6 d-flex">
          <span style={{ flex: "auto" }}>
            The weather station is disconnected as we are moving our office. The
            service will be back on Thursday 6/5/2021
          </span>{" "}
          <i className="fa fa-exclamation-triangle mt-1"></i>
        </div> */}
        {/* {console.log(this.state.data)}
                    {this.state.data !== null? <Weather data={this.state.data} indication={this.state.indication}/> : 'loading...'} */}
        {/* <Weather data={this.state.data} indication={this.state.indication} /> */}
        <div className="px-2 pt-2 pb-0"> 
          <WeatherSummary data={this.state.data}/>
        </div>
        <div className="m-0">
          <WeatherMap weatherCoords={(e) => this.weatherCoords(e)}/>
        </div>
        {/* <RequestInfo /> */}
        {/* <Footer /> */}
        <Header />
      </React.Fragment>
    );
  }
}

export default WeatherContainer;
