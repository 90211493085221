import React, { Component } from 'react';
import levelsService from '../shared/services/levels';
import hubsService from '../shared/services/hubs';
import { getByLevel } from '../shared/services/devices';
import { getEventsByLevel } from '../shared/services/events';
import Water from './water/water'

import RequestInfo from './strip/request-info';
import Description from './barrage-desc/description'
import DescBelow from './barrage-desc/desc-below'
// import Header from '../components/header/header';
// import Footer from '../components/footer';


class BgContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            props:null,
            org_name:'UCPCASW',
            branches:[],
            production:false,
            disableSchedule:false,
            orgsOverview:[]
        };

    }
    loadData = async () => {
        document.getElementById('root_title').innerHTML = 'Linked Things - Barrage'
        if(this.state.data == null){
            let obj = { _id: '', levels: [], hubs: [], devices: [], events:[] }
            let devices = await getByLevel(this.state.org_name);
            let events = await getEventsByLevel(this.state.org_name)
            console.log("Nauman",events)
            obj = {
                devices,
                events,
            };
            this.setState({
                data:obj
            })
        }
    }
     render(){
        this.loadData()
        return(
            <React.Fragment>
                {/* <Header /> */}
                <Description/>
                <Water data={this.state.data} />
                <RequestInfo/>
                <div className="container py-5">
                    <DescBelow/>
                </div>
                {/* <Footer /> */}
            </React.Fragment>
        )
    }
}

export default BgContainer;
