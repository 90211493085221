import React, { Component } from "react";
import { getAqiDevices } from "../../shared/services/events";
import { HashLink as Link } from "react-router-hash-link";
class AqiTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterDevices: [],
    };
  }

  componentWillMount() {
      if (
        this.props.aqiDevices &&
        this.props.dustDevices
      ){
        let filterDevices = [],
            offlineDevies = []
        this.props.aqiDevices.map((device, index) =>
          this.props.dustDevices.map((d) =>{
            if(device.hubId === d.hubId && device.dust === d.event.deviceId){
              if(!(new Date(device.event.created) <= new Date(new Date() - (1000 * 60 * 60)))){
                filterDevices.push({
                  active: true,
                  location:device.location,
                  name:device.level[0].name,
                  created:device.event.created,
                  dust:d.event.value.toFixed(0),
                  aqi:device.event.value,
                })
              }else{
                offlineDevies.push({
                  active: false,
                  location:device.location,
                  name:device.level[0].name,
                  created:device.event.created,
                  dust:d.event.value.toFixed(0),
                  aqi:device.event.value,
                })
              }
            }
          })
        )
        filterDevices = [...filterDevices,...offlineDevies]
        this.setState({
          filterDevices,
        });
      }
  }

  tableColor = (value) => {
    let Colorvalue = parseInt(value),
      tableColor = "",
      red = 0,
      green = 255,
      blue = 0;
    if (Colorvalue > 25 && Colorvalue <= 50) {
      var per = ((Colorvalue - 25) / 25) * 100;
      red = (255 * per) / 100;
      green = 255;
      blue = 0;
    } else if (Colorvalue > 50 && Colorvalue <= 100) {
      var per = ((Colorvalue - 50) / 50) * 100;
      red = 255;
      green = 255 - (102 * per) / 100;
      blue = 0;
    } else if (Colorvalue > 100 && Colorvalue <= 200) {
      red = 255;
      green = 102 - (102 * (Colorvalue - 100)) / 100;
      blue = 0;
    } else if (Colorvalue > 200 && Colorvalue <= 300) {
      red = 200 - (55 * (Colorvalue - 200)) / 100;
      green = 0;
      blue = (255 * (Colorvalue - 225)) / 100;
    } else if (Colorvalue > 300) {
      red = 145;
      green = 0;
      blue = 191;
    }
    // else{
    //     red = 0;
    //     green = 0;
    //     blue = 0;
    // }
    tableColor = "rgb(" + red + "," + green + "," + blue + ")";
    return tableColor;
  };

  render() {
    return (
      <div style={{ height: "500px", fontSize: "16px" }}>
        <div
          className="col-12 bg-dark text-light text-center"
          style={{ fontSize: "12px" }}
        >
          <b>Live Update</b>
        </div>
        <div
          className="col-12 d-flex p-2 align-items-center"
          style={{
            fontWeight: "bold",
            height: "50px",
            boxShadow: "0 5px 10px -5px rgba(0,0,0,0.1)",
          }}
        >
          <span className="col p-2" style={{ width: "60%" }}>
            Location
          </span>
          <span className="py-2 align-items-center mr-1 position-relative" style={{ width: "15%", lineHeight: "16px" }}>
            PM2.5{" "}
            <span style={{ fontSize: "10px", lineHeight: "10px", position: "absolute", left: 6, bottom: -5, }}>
              (µg/m<sup>3</sup>)
            </span>
          </span>
          <span className="p-2" style={{ width: "15%" }}>
            AQI
          </span>
        </div>
        <div style={{ overflowY: "auto", height: "430px" }}>
          <div className="d-table p-2 w-100">
            {
              this.state.filterDevices.map((data,index) =>
                  <div key={index} className="col-12 w-100 d-table-row">
                    <span
                      className="d-table-cell py-2"
                      style={{
                        borderBottom: "1px solid #00000015",
                        width: "75%",
                        fontWeight: "bold",
                        lineHeight: "14px"
                      }}
                    >
                        {index + 1}.
                        {" "+data.name+" "}
                      <small>
                        ({data.location})
                      </small>
                    </span>
                    {data.active?
                    <React.Fragment>
                      <span
                        className="d-table-cell p-2 bg-light text-center"
                        style={{
                          borderBottom: "1px solid #00000015",
                          width: "10%",
                        }}
                      >
                        {data.dust}
                      </span>
                      <span
                        className="d-table-cell p-2"
                        style={{
                          borderBottom: "1px solid #00000015",
                          width: "15%",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: this.tableColor(
                              data.aqi
                            ),
                            borderRadius: "30px",
                            textAlign: "center",
                            fontSize: "14px",
                            color:
                              data.aqi > 100 ? "white" : "black",
                          }}
                        >
                          {data.aqi}
                        </div>
                      </span>
                    </React.Fragment>
                    :
                    <span
                      className="d-table-cell pl-2 py-3"
                      style={{
                        borderBottom: "1px solid #00000015",
                        width: "10%",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "rgb(128, 128, 128)",
                          borderRadius: "30px",
                          textAlign: "center",
                          fontSize: "10px",
                          color: "white",
                        }}
                      >OFFLINE</div>
                    </span>}
                  </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default AqiTable;
