import React, { Component } from "react"
import $ from "jquery";
const uniqueId = new Date().getTime() + "_multiselect"
class Input extends Component {
    constructor(props) {
        super(props)
        let options = []
        if (this.props.options) {
            this.props.options.map(option => {
                options.push({ ...option, checked: false })
            })
        }

        this.state = {
            options,
            selectedOptions: [],
        }
        $(document).ready(function () {
            $("#" + uniqueId).click(function () {
                var elem = $(this).find("+." + uniqueId + "_InputSelect");
                if (elem.css("display") === "none") {
                    elem.fadeIn(300);
                } else {
                    elem.fadeOut(300);
                }
            });
        });
    }
    dropdownOpen = () => {
        var elem = $("." + uniqueId + "_InputSelect");
        if (elem.css("display") === "none") {
            elem.fadeIn(300);
        } else {
            elem.fadeOut(300);
        }
    }
    onDevicesSelection = (event,render) => {
        let value = event.target.value
        let isCheck = event.target.checked
        let checkValue = false
        let selectedOptions = this.state.selectedOptions
        if (selectedOptions.includes(value)) {
            if (!isCheck) {
                var index = selectedOptions.indexOf(value);
                if (index !== -1) {
                    selectedOptions.splice(index, 1);
                }
                checkValue = false
            }
        } else {
            selectedOptions.push(value)
            checkValue = true
        }
        let index2 = null
        this.state.options.map((opt, idx) => { if (opt.value === value) { index2 = idx } })
        this.state.options[index2].checked = checkValue
        if ("onChange" in this.props && render) {
            let e = {
                target: {
                    value: selectedOptions
                }
            }
            this.props.onChange(e)
        }
        this.setState({ selectedOptions, options: this.state.options })
    }
    componentDidMount(){
        if("defaultValue" in this.props){
            if('multiRow' in this.props.defaultValue){
                this.props.defaultValue.multiRow.map((data ,index)=> {
                    let filterData = this.state.options[data]
                    filterData = {
                        target: {
                            value: filterData.value,
                            checked: true
                        }
                    }
                    this.onDevicesSelection(filterData, this.props.defaultValue.multiRow.length === index + 1)
                })
            }
        }
    }

    render() {
        return (
            <div
                title="Compare with"
            >
                <label className="m-0" style={this.state.selectedOptions.length ? { transform: "translateY(0px) scale(1)", transition: "all 0.3s" } : { transform: "translateY(30px) scale(0.9)", transition: "all 0.3s", color: "grey" }}>{"label" in this.props ? this.props.label : "Select"}</label>
                <div style={{ position: "relative" }}>
                    <div
                        id={uniqueId}
                        className="py-1 compareInput"
                        style={{
                            borderBottom: "1px solid rgb(150,150,150)",
                            height: "32px",
                            cursor: "pointer",
                            overflow: "hidden",
                            overflowWrap: "normal",
                        }}
                    >
                        {this.state.selectedOptions.length ? this.state.selectedOptions.map(opt =>
                            <span class="p-1 px-2 mr-1 rounded-circle-pxl multiSelectTag" style={{ backgroundColor: " rgb(240, 240, 240)", fontSize: 12 }} onClick={() => this.onDevicesSelection({ target: { value: opt, checked: false } }, true)}> {
                                this.state.options.find((data)=> data.value === opt).label
                            } 
                            </span>
                        ) : null
                            // <span style={{ color: "grey" }}>{"label" in this.props ? this.props.label : "Select"}</span>
                        }
                    </div>
                    <div
                        className={uniqueId + "_InputSelect dropshadow rounded-1 bg-white px-3 pt-3 pb-2 w-100"}
                        style={{
                            position: "absolute",
                            top: "40px",
                            left: 0,
                            zIndex: 1,
                            display: "none",
                            overflow: "auto",
                            maxHeight: "300px",
                        }}
                    >
                        <div className="d-flex justify-content-end position-sticky" style={{top:0}}><i className="fa fa-times" onClick={()=>this.dropdownOpen()}></i></div>
                        {this.state.options.length ? this.state.options.map((options, index) => (
                            <label className="chartSelect w-100" key={index}>
                                <input
                                    type="checkbox"
                                    value={options.value}
                                    onChange={(event) => this.onDevicesSelection(event, true)}
                                    checked={options.checked}
                                />
                                &nbsp;
                                {options.label}
                            </label>
                        )) :
                            <pre className="px-3 m-0">
                                please add 'options' attribute<br />
                                {"options = ["}<br />
                                {"    {value: 123, label: 'abc'}"}<br />
                                {"]"}<br />
                                for default selected<br />
                                defaultValue = {'{{row: num}}'}<br />
                                -OR-<br />
                                defaultValue = {'{{label: "text"}}'}<br />
                                -OR-<br />
                                defaultValue = {'{{value: num or "text"}}'}
                            </pre>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Input