import React, { Component } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import _ from 'lodash';
import $ from 'jquery';
import * as functions from '../../functions/functions'
import { getAqiMinMaxByLevel, getAqiMinMaxByDevice } from '../../shared/services/events'

import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import 'chartjs-plugin-annotation';
import Loader from '../../components/loader1'

import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
} from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';

const brandPrimary = getStyle('--primary')
const brandSuccess = getStyle('--success')
const brandInfo = getStyle('--info')
const brandWarning = getStyle('--warning')
const brandDanger = getStyle('--danger')

let changeDataChecker;
class BarGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            download: true,
            initialLoad: true,
            unmount: true,
            width: 0,
            limit: 10,
            rand: 0.0,
            radioSelected: 1,
            renderVar: 20,
            overflow: 'hidden',
            graphDate: null,
            isLoader: false,
            loadingdownload: false,
            volume: 0.0,
            consumption: 0.0,
            xlsxData: null,
            bill: 0,
            mainChartOpts:
            {
                annotation: {
                    drawTime: 'afterDatasetsDraw',
                    annotations: [
                        {
                            type: "line",
                            mode: "vertical",
                            scaleID: "x-axis-0",
                            value: "23-03-2020",
                            borderColor: 'Black',
                            borderWidth: 1.5,
                            borderDash: [5, 5],
                            label: {
                                // backgroundColor: 'red',
                                fontSize: 9,
                                fontStyle: "normal",
                                fontColor: "#fff",
                                // position: "top",
                                enabled: true,
                                content: "Lockdown Begins",
                                rotation: -90,
                            },
                        },
                    ]
                },
                tooltips: {
                    // enabled: false,
                    // custom: CustomTooltips,
                    intersect: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        label: function (tooltipItems, chart) {
                            return chart.datasets[tooltipItems.datasetIndex].label + tooltipItems.yLabel + ' at ' + chart.datasets[tooltipItems.datasetIndex].time[chart.labels.indexOf(tooltipItems.xLabel)];
                        },
                        labelColor: function (tooltipItem, chart) {
                            return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: 10,
                                // display: false,
                                maxTicksLimit: 10,
                            },
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            /*  type: 'time',
                             distribution: 'linear', */
                            time: {
                                /*   displayFormats: {
                                    minute: 'h:mm:ss a[\n]ll',
                                    millisecond: 'h:mm:ss a[\n]ll',
                                    second: 'h:mm:ss a[\n]ll',
                                    hour: 'h:mm:ss a[\n]ll',
                                    day: 'h:mm:ss a ll',
                                    week: 'h:mm:ss a ll',
                                    month: 'h:mm:ss a ll',
                                    quater: 'h:mm:ss a ll',
                                    year: 'h:mm:ss a [\n] ll'
                                   
                                } */
                            }
                        }],
                    yAxes: [
                        {
                            ticks: {
                                fontSize: 10,
                                beginAtZero: true,
                                maxTicksLimit: 6
                                // max: 250
                                // display: false
                                //  stepSize: Math.ceil(250 / 5),
                            }
                        }],
                },
                elements: {
                    point: {
                        radius: 2,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3,
                    },
                },
            },
            mainChart: {
                labels: [],
                datasets: [],
            },
        }

        this.getAqiGraph = this.getAqiGraph.bind(this);

    }
    async componentDidMount() {
        $(window).resize(() => {
            this.state.unmount = true;
            this.state.limit = 100;
            this.state.renderVar = 20;
            this.state.unmount = false;
            this.forceUpdate();
        });
        setTimeout(() => {
            this.getAqiGraph();
        }, 500);
    }

    async getAqiGraph() {
        let data;
        if (this.props.type === 'Level') {
            data = await getAqiMinMaxByLevel(this.props._id, 'daily');
        }
        if (this.props.type === 'Device') {
            data = await getAqiMinMaxByDevice(this.props._id, 'daily');
        }

        if (!data.error) {
            var len = data.length;
            var i;
            var newMin = [];
            var newMinTime = [];
            var newMax = [];
            var newMaxTime = [];
            var newLabel = [];
            for (i = 0; i < len; i++) {
                newLabel.push(functions.convertToDate(data[i].created, 'date'));
                newMin.push(data[i].min.value);
                newMinTime.push(functions.formatAMPMHours(data[i].min.hour, 0));
                newMax.push(data[i].max.value);
                newMaxTime.push(functions.formatAMPMHours(data[i].max.hour, 0));
            }
            newMin.reverse();
            newMinTime.reverse();
            newMax.reverse();
            newMaxTime.reverse();
            newLabel.reverse();
            var prevData = this.state.mainChart;
            prevData.datasets = [
                {
                    label: 'Max: ',
                    backgroundColor: hexToRgba(brandWarning, 50),
                    borderColor: brandWarning,
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: newMax,
                    time: newMaxTime
                },
                {
                    label: 'Min: ',
                    backgroundColor: hexToRgba(brandInfo, 50),
                    borderColor: brandInfo,
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: newMin,
                    time: newMinTime
                }
            ]

            prevData.labels = newLabel;

            this.setState({
                mainChart: prevData
            })



            this.state.unmount = true;
            if (len == 0) {
                this.state.limit = 100;
                this.state.renderVar = 20;
                this.state.overflow = 'hidden';

            }
            else {
                var graphWidth = $("#graph").width();

                var approxWidth = 20 * len
                if (approxWidth <= graphWidth) {
                    var renderVar = graphWidth / len;
                    this.state.renderVar = renderVar;
                    this.state.overflow = 'hidden'
                }
                else {
                    this.state.overflow = 'scroll'
                    this.state.renderVar = 20;
                }
                this.state.limit = len;
            }

            this.forceUpdate();

            this.state.unmount = false;
            this.forceUpdate();

        }
    }

    // defaultValue={this.todayDate('end')} onChange={this.graphDateHandler.bind(this)}
    render() {
        return (
            <div className="dropshadow rounded row">
                {(this.state.unmount) ?
                    <Loader/> :
                    <Card className="w-100" style={{ border: '0px' }}>
                    <CardBody style={{ padding: '10px' }}>
                        <div>
                            < CardTitle className="mb-1 gradient-primary1 p-3 mb-3 text-light rounded" style={{ fontSize: '16px' }}><b>{this.props.title}</b></CardTitle>

                            <div><span className="mt-1" style={{ height: '14px', width: '14px', backgroundColor: brandWarning, borderRadius: '50%', display: 'inline-block' }}></span><h6 className="d-inline pl-2" style={{ fontSize: '14px', fontWeight: '400' }}>Maximum </h6></div>

                            <div><span className="mt-1" style={{ height: '14px', width: '14px', backgroundColor: brandInfo, borderRadius: '50%', display: 'inline-block' }}></span><h6 className="d-inline pl-2" style={{ fontSize: '14px', fontWeight: '400' }}>Minimum </h6></div>
                        </div>

                        <div id={"graph"} className="chart-wrapper" style={{ overflowX: this.state.overflow, marginTop: 20 + 'px' }} rand={this.state.rand}>
                                <Bar redraw={this.state.shouldRedraw}
                                    height={(this.props.type === "Device") ? 200 : 300}
                                    options={{
                                        maintainAspectRatio: true
                                    }} data={this.state.mainChart} options={this.state.mainChartOpts} rand={this.state.rand} />
                        </div>
                    </CardBody>
                </Card >
                }
        </div>
        )
    }

}

export default BarGraph;