import React, { useLayoutEffect, useState } from 'react'
import {
  Map,
  Marker,
  GoogleApiWrapper,
  Circle,
} from "google-maps-react";
import { getMapDevice, getEventsByDeviceList, getMapDeviceIBM, getEventsByDeviceListIBM } from '../../shared/services/events';
import ChartConfig from '../../components/chartConfig';
import "./weatherMap.css"
import styles from './mapStyles.json'
import TemperatureIcon from "../../assets/weather/temperature.svg" 
import HumidityIcon from "../../assets/weather/humidity.svg" 
import RainIcon from "../../assets/weather/rain.svg" 
import AirIcon from "../../assets/weather/air.svg" 
import GustIcon from "../../assets/weather/gust.svg" 
import UVIcon from "../../assets/weather/uv.svg" 
import UVIIcon from '../../assets/weather/uvi.svg';
import IlluminanceIcon from "../../assets/weather/illuminance.svg" 
import AQIIcon from "../../assets/weather/aqi.svg" 
import DustIcon from "../../assets/weather/dust.png" 
import HeatIndex from '../../assets/device/heatIndex/heatIndex.svg';
import CompassIcon from '../../components/weather/compassIcon';
import moment from 'moment';
import { convertToDate, getDate, getTime } from '../../functions/functions';

import Loader from "../../components/loader1";
import Input from '../../components/input';
const address = "https://gateway.s5.ottomatically.com/"
function WeatherMap(props) {
    const [isLoader, setIsLoader] = useState(true)
    const [devices, setDevices] = useState([])
    const [sideBarToggle, setSideBarToggle] = useState(true)
    const [lastUpdate, setLastUpdate] = useState(null)
    const [layers, setLayers] = useState([
        {name:"AQI", unit: "Index", imgSrc: AQIIcon, isChecked: true},
        {name:"Gust", unit: "km/hr", imgSrc: GustIcon, isChecked: false},
        {name:"HeatIndex", unit: "index", imgSrc: HeatIndex, isChecked: false},
        {name:"Humidity", unit: "percentage", imgSrc: HumidityIcon, isChecked: false},
        // {name:"Illuminance", unit: "klux", imgSrc: IlluminanceIcon, isChecked: false},
        {name:"Rain", unit: "mm/day", imgSrc: RainIcon, isChecked: false},
        {name:"Temperature", unit: "°C", imgSrc: TemperatureIcon, isChecked: false},
        {name:"UV", unit: "µW/cm2", imgSrc: UVIcon, isChecked: false},
        {name:"UVI", unit: "index", imgSrc: UVIIcon, isChecked: false},
        {name:"WindDirection", unit: "degree", imgSrc: null, isChecked: false, component: <CompassIcon value={0}/>},
        {name:"WindSpeed", unit: "km/hr", imgSrc: AirIcon, isChecked: false},
    ])
    const [selectedLayer, setSelectedLayer] = useState("AQI")
    const [popupData, setPopupData] = useState(null)
    const [popupCompare, setPopupCompare] = useState(null)
    const [fullScreen, setFullScreen] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [mapMode, setMapMode] = useState(0)
    const [screenSize, setScreenSize] = useState(0)
    
    useLayoutEffect(() => {
        fetchData();
        let fetch = setInterval(() => { fetchData() }, 300000)
        document.addEventListener('fullscreenchange', onFullScreenChange);
        onScreenResize()
        document.addEventListener('resize', onScreenResize);
        return(()=> {
            clearInterval(fetch)
            document.removeEventListener("fullscreenchange", onFullScreenChange);
            document.removeEventListener('resize', onScreenResize);
        })
    }, [])

    const onFullScreenChange = () => {
        if (document.fullscreenElement) {
            setFullScreen(true)
        }
        else {
            setFullScreen(false)
        }
    }
    const onScreenResize = () => {
        const windowWidth = document.documentElement.clientWidth || document.body.clientWidth;
        // console.log(windowWidth < 768);
        setScreenSize(windowWidth)
    }
    const getTileUrl = function (tile, zoom) {
        let currentDate = moment().toISOString()
        currentDate = currentDate.split(".")
        currentDate = currentDate[0].split(":")
        let fixedMinute = currentDate[1]-currentDate[1]%5
        if(fixedMinute < 10){
            fixedMinute = "0"+fixedMinute
        }
        currentDate = currentDate[0]+":"+fixedMinute+":00Z"
        //Clouds (Sattelite )
        // ?apikey=de13920f574d420984d3080b1fa6132b
        if(mapMode === 1){
            return address+'maps/v1/satellite/globalColor/zxy/'+currentDate+'/' +
            zoom + '/' + tile.x + '/' +
            tile.y + '.png?display_mode=20'
        }
        else if(mapMode === 2){
            //water vapours
            return address+'maps/v1/satellite/globalWV/zxy/'+currentDate+'/' +
            zoom + '/' + tile.x + '/' +
            tile.y + '.png'
        }
        else if(mapMode === 3){
            //globaSlIR (Radar data = > rain snow )
            return address+'maps/v1/radar/globalSIR/zxy/'+currentDate+'/' +
            zoom + '/' + tile.x + '/' +
            tile.y + '.png'
        }
      };

    const fullScreens = () => {
        var elem = document.getElementById("mapContainer");
        if (!fullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }
    const fetchData = async () => {
        let device = []
        let repsonseTemp = await getMapDevice("Weather")
        let aqiDevices = await getMapDeviceIBM("Karachi");
        device = [...device,...arrangeData(repsonseTemp),...arrangeData(aqiDevices,"AQI")]
        if(popupData){
            let data = {...popupData}
            device.map(device1=>{
                if(data._id === device1.hubId){
                    if(device1.search === "WindDirection"){
                        data['created'] = device1.created;
                    }
                    data.list.push({
                        search:device1.search,
                        value:device1.value,
                        name:device1.device1Name,
                    })
                }
            })
            if(!"created" in data){
                data['created'] = device[0].created;
            }
            setPopupData(data)
        }
        setDevices(device)
        setIsLoader(false)
    }
    const arrangeData = (response,typeOnly = null)=>{
        let lastUpdate = "";
        let device = []
        let locations = []
        response.map(row=>{
            let splitId = row._id.split("_")
            if(
                (row.type != "Moisture" && row.type != "Gps" && row.type != "Pressure" && splitId[2] != "SoilTemperature" && splitId[2] != "DewPoint" && row.type !== "Dust" && typeOnly !== "AQI") || 
                (typeOnly === "AQI" && (row.type === "AQI" || row.type === "Dust"))
                ){
                let rowData = {}
                rowData['active'] = false;
                if("metadata" in row.level[0]){
                    if("coordinates" in row.level[0].metadata){
                        rowData["coordinates"] = row.level[0].metadata.coordinates
                    }
                }
                rowData['hubId'] = row.hubId
                rowData['_id'] = row._id
                rowData['name'] = row.location
                rowData['deviceName'] = row.name
                if(splitId[2] === selectedLayer){
                    locations.push({label:row.location,value:row._id})
                }
                rowData["search"] = splitId[2]
                rowData["value"] = "N/A"
                if("event" in row){
                    rowData["type"] = row.event.type
                    rowData["created"] = row.event.created.split("Z")

                    rowData["created"] = rowData["created"][0]
                    if(lastUpdate){
                        if(new Date(lastUpdate) < new Date(rowData["created"])){
                            lastUpdate = rowData["created"]
                        }
                    }else{
                        lastUpdate = rowData["created"]
                    }
                    var a = moment();
                    var b = moment(rowData["created"]);
                    let newDate = a.diff(b,'minute');
                    if(newDate>60){
                        rowData["online"] = false
                    }else{
                        rowData["online"] = true
                    }
                    rowData["value"] = row.event.value
                }
                if(rowData["value"] !== "N/A"){
                    device.push(rowData)
                }
            }
        })
        setLocationList(locations)
        setLastUpdate(lastUpdate)
        return device
    }
    const getColor = (index) => {
        if (index !== -1) {
            let Colorvalue = parseInt(index),
            tableColor = "",
            red = 0,
            green = 255,
            blue = 0;
            if (Colorvalue > 25 && Colorvalue <= 50) {
                var per = ((Colorvalue - 25) / 25) * 100;
                red = (255 * per) / 100;
                green = 255;
                blue = 0;
            } else if (Colorvalue > 50 && Colorvalue <= 100) {
                var per = ((Colorvalue - 50) / 50) * 100;
                red = 255;
                green = 255 - (102 * per) / 100;
                blue = 0;
            } else if (Colorvalue > 100 && Colorvalue <= 200) {
                red = 255;
                green = 102 - (102 * (Colorvalue - 100)) / 100;
                blue = 0;
            } else if (Colorvalue > 200 && Colorvalue <= 300) {
                red = 200 - (55 * (Colorvalue - 200)) / 100;
                green = 0;
                blue = (255 * (Colorvalue - 225)) / 100;
            } else if (Colorvalue > 300) {
                red = 145;
                green = 0;
                blue = 191;
            }
            tableColor = "rgb(" + red + "," + green + "," + blue + ")";
            return tableColor;
        } else {
            return "rgb(200,200,200)"
        }
    }
    const setMarker = (value,online) => {
        let fontSize;
        if (value === "-1") {
          fontSize = "30px";
        } else {
          fontSize = "20px";
        }
        if(value.toString().length > 4){
            fontSize = "14px";
        }
        return (
            '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 47.92 69.35" xmlns:xlink="http://www.w3.org/1999/xlink">'+
            '<defs><linearGradient id="marker_id0" gradientUnits="userSpaceOnUse" x1="-3.98" y1="34.83" x2="92.76" y2="34.85">'+
            '<stop offset="0" style="stop-opacity:1; stop-color:#0D3C60"/>'+
            '<stop offset="1" style="stop-opacity:1; stop-color:#1A72B7"/>'+
            '</linearGradient></defs>'+
            '<g>'+
            '<path fill="'+(!online?"grey":(selectedLayer === "AQI"? getColor(value): "url(#marker_id0)"))+'" d="M23.96 0c13.23,0 23.96,10.73 23.96,23.96 0,4.14 -1.05,8.03 -2.9,11.43l0 -0 -0.01 0.01c-0.43,0.8 -0.91,1.57 -1.43,2.31l-19.63 31.64 -19.63 -31.64c-0.52,-0.74 -1,-1.51 -1.43,-2.31l-0.01 -0.01 0 0c-1.85,-3.4 -2.9,-7.29 -2.9,-11.43 0,-13.23 10.73,-23.96 23.96,-23.96z"/>'+
            '<circle fill="white" cx="23.96" cy="23.96" r="21.34"/>'+
            '<text dominant-baseline="middle" text-anchor="middle" x="50%" y="37%" fill="black" style="font-weight:bold;font-size:' +
                fontSize +';font-family:Arial">' +
                (online?value:"OFF") +
            "</text>" +
            '</g></svg>'
        );
        //   '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd;" viewBox="0 0 16746 24233" xmlns:xlink="http://www.w3.org/1999/xlink">' +
        //   "<defs>" +
        //   '<linearGradient id="marker_id0" x1="0" y1="0" x2="100%" y2="100%">' +
        //   '<stop offset="0" style="stop-opacity:1; stop-color:#1b75bc"/>' +
        //   '<stop offset="1" style="stop-opacity:1; stop-color:#0d3a5d"/>' +
        //   "</linearGradient>" +
        //   "</defs>" +
        //   "<g>" +
        //   '<path fill="'+(!online?"grey":"url(#marker_id0)")+'" d="M8373 0c4624,0 8373,3749 8373,8373 0,3614 -2289,6693 -5497,7866l-2876 7994 -2876 -7994c-3208,-1173 -5497,-4252 -5497,-7866 0,-4624 3749,-8373 8373,-8373z"/>' +
        //   '<circle fill="white" cx="8373" cy="8373" r="6418"/>' +
        //   '<text dominant-baseline="middle" text-anchor="middle" x="50%" y="37%" fill="black" style="font-weight:bold;font-size:' +
        //   fontSize +
        //   ';font-family:Arial">' +
        //   (online?value:"OFF") +
        //   "</text>" +
        //   "</g>" +
        //   "</svg>"
    }
    const onChangeHandler = (e)=>{
        setLocationList([])
        let locations = []
        let value = e.target.value
        let layers1 = layers
        devices.map(row=>{
            if(row.search === value){
                locations.push({label:row.name,value:row._id})
            }
        })
        layers1.map((layer,index)=>{
            if(layer.name === value){
                layers1[index].isChecked = true
                setSelectedLayer(value)
            }else{
                layers1[index].isChecked = false
            }
        })
        setTimeout(() => { 
            setLocationList(locations)
         }, 300)
    }
    const onMarkerClick = (_id,name,key,coords)=>{
        props.weatherCoords({coords,name})
        let data = {_id,name,list:[]}
        let devices1 = [...devices]
        devices.map((device,key2)=>{
            devices1[key2].active = false;
            if(_id === device.hubId){
                if(device.search === "WindDirection"){
                    data['created'] = device.created;
                }
                data.list.push({
                    search:device.search,
                    value:device.value,
                    name:device.deviceName,
                })
            }
        })
        if(!"created" in data){
            data['created'] = devices[0].created;
        }
        data.list = data.list.sort((a,b)=>a.search.localeCompare(b.search))
        devices1[key].active = true
        setDevices(devices1)
        setPopupData(data)
    }
    const comparisionFunc = async (selectiedIds = [])=>{
        setPopupCompare({
            loader: true
        })
        let chartConfig = {
            id:"comparisionGraph",
            axisX: "date",
            axisY: "value",
            scrollbar: true,
            series:[]
        }
        let chartData = []
        let chartDataItem = null
        let deviceIds = ""
        let types = ""
        let tempInd = 0;
        devices.map((row,keys)=>{
            if(row.search === selectedLayer && tempInd < 5){
                if(!selectiedIds.length){
                    tempInd += 1
                    if(deviceIds){
                        deviceIds += ","
                    }
                    if(types){
                        types += ","
                    }
                    deviceIds += row._id
                    types += row.type    
                    chartConfig.series.push({
                        lineOnly: true,
                        type: "line",
                        name: row.name,
                        color: null,
                        x: "date",
                        y: row._id,
                    })
                }else{
                    if(selectiedIds.includes(row._id)){
                        tempInd += 1
                        if(deviceIds){
                            deviceIds += ","
                        }
                        if(types){
                            types += ","
                        }
                        deviceIds += row._id
                        types += row.type    
                        chartConfig.series.push({
                            lineOnly: true,
                            type: "line",
                            name: row.name,
                            color: null,
                            x: "date",
                            y: row._id,
                        })
                    }
                }
                if(!chartDataItem){
                    chartDataItem = {date: null}
                }
                chartDataItem[row._id] = null
                }
            })
            // selectiedIds.map((row,index) => {

            // })
        let startDate = moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss[Z]');
        let endDate = moment().format('YYYY-MM-DDTHH:mm:ss[Z]');
        let res = null
        if(selectedLayer === "AQI"){
            res = await getEventsByDeviceListIBM(deviceIds,types,null,null)
        }else{
            res = await getEventsByDeviceList(deviceIds,types,null,null)
        }
        res.map((row1,key1)=>row1.length > 1? row1.map((row2,key2)=>{
            let tempChartDataItem = {...chartDataItem}
            tempChartDataItem[row2.deviceId] = row2.value
            let newHour = new Date(row2.created).getHours() - 5
            tempChartDataItem.date = new Date(row2.created).setHours(newHour)
            chartData.push(tempChartDataItem)
        }): null)
        chartData = chartData.sort((a, b) => new Date(a.date) - new Date(b.date));
        setPopupCompare({
            loader: false,
            chartConfig,
            chartData,
        })
        
    }
    const selectedLoaction = (e) => {
        comparisionFunc(e.target.value)
    }
    const _mapLoaded = (mapProps, map) => {
        if(map && mapMode){
            var layerOptions = {
                alt: 'MODIS_Terra_Aerosol',
                getTileUrl: getTileUrl,
                maxZoom: 10,
                minZoom: 2,
                name: 'MODIS_Terra_Aerosol',
                opacity: 0.7,
            };
            var imageMapType = new props.google.maps.ImageMapType(layerOptions);
            map.overlayMapTypes.insertAt(0, imageMapType);
        }
     }
     const modeChangeHandler = (value) => {
        setIsLoader(true)
        setMapMode(value)
        setTimeout(() => {
            setIsLoader(false)
         }, 10)
     }
  return (
    <div id="mapContainer">
        <div className='row m-0 mapMode'>
            {/* <h4></h4> */}
            <div className={'col mapModeBtn'+(mapMode === 0?' active':'')} onClick={()=>modeChangeHandler(0)}>DEVICE</div>
            <div className={'col mapModeBtn'+(mapMode === 1?' active':'')} onClick={()=>modeChangeHandler(1)}>SATELLITE</div>
            <div className={'col mapModeBtn'+(mapMode === 2?' active':'')} onClick={()=>modeChangeHandler(2)}>Moisture</div>
            <div className={'col mapModeBtn'+(mapMode === 3?' active':'')} onClick={()=>modeChangeHandler(3)}>RADAR</div>
        </div>
      <div className='position-relative overflow-hidden' style={{height:fullScreen?"calc(100vh - 70px)":"calc(100vh - 285px)"}}>
        {!isLoader?
        <>
            <Map
            google={props.google}
            initialCenter={{ lat: 24.891465, lng: 67.081024 }}
            maxZoom={mapMode?10:19}
            // maxZoom={}
            minZoom={mapMode?2:0}
            styles={mapMode === 1?styles.dark:styles.light}
            onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            zoom={mapMode?4:(screenSize < 768?10:11)}
            visible={true}
            mapTypeControl={false}
            streetViewControl={false}
            fullscreenControl={false}
            >
                {/* {!mapMode ? devices.map((row,keys)=>
                    row.search === selectedLayer?
                    row.search === "AQI"?
                    <Circle
                        radius={1000}
                        center={row.coordinates}
                        strokeColor='transparent'
                        strokeOpacity={0}
                        strokeWeight={5}
                        fillColor={getColor(row.value)}
                        fillOpacity={0.6}
                    />
                    :null
                    :null
                ) :null} */}
                {!mapMode ? devices.map((row,keys)=>
                    row.search === selectedLayer?
                    <Marker
                    title={row.name + " ("+row.value+")"}
                    key={keys}
                    name={row.name}
                    onClick={()=>onMarkerClick(row.hubId,row.name,keys,row.coordinates)}
                    position={row.coordinates}
                    zIndex={row.online?1:0}
                    icon={{
                    url:
                        "data:image/svg+xml;charset=UTF-8;base64," +
                        btoa(setMarker(row.value,row.online)),
                        scaledSize: new props.google.maps.Size(
                            screenSize < 768?(row.active?80:(row.online?40:20)):(row.active?120:(row.online?60:40)),
                            screenSize < 768?(row.active?80:(row.online?40:20)):(row.active?120:(row.online?60:40))
                        )
                    }}
                    />
                    :null
                ) :null}
            </Map>
                <button id="fullscreenBtn" onClick={()=>fullScreens()}><i className={"fas fa-"+(!fullScreen?"expand":"compress")}></i></button>
                {!mapMode ? 
                <>
                    {lastUpdate?
                        <div
                        className="lastUpdate bg-dark text-light text-center"
                        style={{ fontSize: "12px" }}
                        >
                            Last Update:{" "}
                            {convertToDate(lastUpdate)}
                        </div>
                    :null}
                    <button id="comparisonBtn" onClick={()=>setPopupCompare({loader: true})}><i className="fas fa-balance-scale"></i> Comparision Graph</button>
                    {selectedLayer === "AQI"? 
                        <div className="text-center position-absolute" style={{bottom:10, left: 50, right: 50}}>
                            <span
                            className="mx-1"
                            style={{
                                backgroundColor: "#10ff00",
                                padding: "5px 10px",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                                borderRadius: "50px",
                                lineHeight: "30px",
                            }}
                            >
                            Good
                            </span>
                            <span
                            className="mx-1"
                            style={{
                                backgroundColor: "#fff200",
                                padding: "5px 10px",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                                borderRadius: "50px",
                                lineHeight: "30px",
                            }}
                            >
                            Moderate
                            </span>
                            <span
                            className="mx-1"
                            style={{
                                backgroundColor: "#ffae00",
                                padding: "5px 10px",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                                borderRadius: "50px",
                                lineHeight: "30px",
                            }}
                            >
                            Unhealthy
                            </span>
                            <br className="d-md-none" />
                            <span
                            className="mx-1"
                            style={{
                                backgroundColor: "#ff0000",
                                padding: "5px 10px",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                                borderRadius: "50px",
                                lineHeight: "30px",
                            }}
                            >
                            Very Unhealthy
                            </span>
                            <span
                            className="mx-1"
                            style={{
                                backgroundColor: "#bf00ff",
                                padding: "5px 10px",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                                borderRadius: "50px",
                                lineHeight: "30px",
                            }}
                            >
                            Hazardous
                            </span>
                        </div>
                    :null}
                </>
                :mapMode === 3 ? 
                <div className="rainLegends">
                    <div>
                        <span>Rain</span>
                        <ul>
                            <li><i className='fa fa-circle' style={{color:'#93c701'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#ffd701'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#f05514'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#dc250e'}}></i></li>
                        </ul>
                    </div>
                    <div>
                        <span>Snow</span>
                        <ul>
                            <li><i className='fa fa-circle' style={{color:'#9ec8f2'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#2a8fdb'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#144bed'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#020096'}}></i></li>
                        </ul>
                    </div>
                    <div>
                        <span>Ice</span>
                        <ul>
                            <li><i className='fa fa-circle' style={{color:'#e6a5c8'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#d24fa0'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#b71691'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#7a1570'}}></i></li>
                        </ul>
                    </div>
                    <div>
                        <span>Mix</span>
                        <ul>
                            <li><i className='fa fa-circle' style={{color:'#c196e6'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#ae6ee6'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#8a32d7'}}></i></li>
                            <li><i className='fa fa-circle' style={{color:'#6500ba'}}></i></li>
                        </ul>
                    </div>
                </div>
                :mapMode === 2 ?
                <div className="waterVapourLegends px-2 py-1 p-md-3">
                    <div className='title'>Water Vapor</div>
                    <ul>
                        <li style={{backgroundColor:'maroon'}}></li>
                        <li style={{backgroundColor:'#910000'}}></li>
                        <li style={{backgroundColor:'#731e00'}}></li>
                        <li style={{backgroundColor:'#353535'}}></li>
                        <li style={{backgroundColor:'#828282'}}></li>
                        <li style={{backgroundColor:'#b1b1b1'}}></li>
                        <li style={{backgroundColor:'#ffffff'}}></li>
                        <li style={{backgroundColor:'#d24fa0'}}></li>
                        <li style={{backgroundColor:'#9b1682'}}></li>
                        <li style={{backgroundColor:'#039385'}}></li>
                        <li style={{backgroundColor:'#96da55'}}></li>
                        <li style={{backgroundColor:'#479702'}}></li>
                        <li style={{backgroundColor:'#165b02'}}></li>
                    </ul>
                    <div className='smallLegends d-flex justify-content-between'>
                        <span>Dry</span>
                        <span>Moist</span>
                    </div>
                </div>
                :mapMode === 1 ?
                <div className="waterVapourLegends satelliteLegends">
                    <div className='title'>Clouds</div>
                    <ul>
                        <li style={{backgroundColor:'#fff'}}></li>
                        <li style={{backgroundColor:'#d24fa0'}}></li>
                        <li style={{backgroundColor:'#8a32d7'}}></li>
                        <li style={{backgroundColor:'#144bed'}}></li>
                        <li style={{backgroundColor:'#479702'}}></li>
                        <li style={{backgroundColor:'#72b403'}}></li>
                        <li style={{backgroundColor:'#93c701'}}></li>
                        <li style={{backgroundColor:'#ffd701'}}></li>
                        <li style={{backgroundColor:'#f05514'}}></li>
                        <li style={{backgroundColor:'#dc250e'}}></li>
                        <li style={{backgroundColor:'#ba0808'}}></li>
                        <li style={{backgroundColor:'#1f1f1f'}}></li>
                    </ul>
                    <div className='smallLegends d-flex justify-content-between'>
                        <span>Low</span>
                        <span>High</span>
                    </div>
                </div>
                :null}
            </>
        :<Loader/>}
        {!mapMode ? 
            <>
                <div className='dropshadow bg-white rounded-1 mapSideBar' style={{transform:sideBarToggle?"translateX(0%)":"translateX(calc(-100% - 10px))"}}>
                    <div className='btn btn-light arrowButton' onClick={()=>setSideBarToggle(!sideBarToggle)}><i className={'fa fa-angle-'+(sideBarToggle?"left":"right")}></i></div>
                    <h5 className='py-3 px-4 m-0'>Layers</h5>
                    <div className='typeList'>
                        {layers.map((layer,keys)=>
                            <label key={keys}>
                                {layer.imgSrc?
                                    <img className='img' src={layer.imgSrc} alt={layer.name}/>
                                :
                                <div className='img'>
                                    {layer.component}
                                </div>
                                }
                                <div className='d-md-flex d-none flex-column text-center '>
                                    {layer.name}
                                    <small>({layer.unit})</small>
                                </div>
                                <input type='checkbox' onChange={(e)=>onChangeHandler(e)} name="type" value={layer.name} checked={layer.isChecked}/>
                            </label>
                        )}
                    </div>
                </div>
                <div className={'dropshadow bg-white rounded-1 mapSideBar'+(popupData?' d-inline':' d-none')}>
                {popupData?
                    <React.Fragment>
                        <div className='btn btn-light w-100 closeBtn' onClick={()=>{
                                setPopupData(null);
                                let devices1 = [...devices];
                                devices1 = [...devices];
                                devices1.map(device => device.active = false);
                                setDevices(devices1)
                                props.weatherCoords(null)
                            }}><i className={'fa fa-angle-left text-left w-100'}></i>
                        <h5 className='text-truncate m-0'>{popupData.name}</h5>
                        </div>
                        <div className='typeList'>
                            {popupData.list.map((row,keys)=>
                                <label key={keys}>
                                        {row.search === "WindDirection"?
                                            <div style={{height:50}}>
                                                <CompassIcon value={row.value}/>
                                            </div>
                                        :
                                            <img style={{width:50,height:50}} src={
                                                row.search === "AQI"?
                                                AQIIcon
                                                :row.search.startsWith("Dust")?
                                                DustIcon
                                                :row.search === "Temperature" || row.search === "IndoorTemp"?
                                                TemperatureIcon
                                                :row.search === "Humidity" || row.search === "IndoorHumidity"?
                                                HumidityIcon
                                                :row.search === "Rain"?
                                                RainIcon
                                                :row.search === "WindSpeed"?
                                                AirIcon
                                                :row.search === "Gust"?
                                                GustIcon
                                                :row.search === "UV"?
                                                UVIcon
                                                :row.search === "UVI"?
                                                UVIIcon
                                                :row.search === "Illuminance"?
                                                IlluminanceIcon
                                                :row.search === "HeatIndex"?
                                                HeatIndex
                                                :null
                                            }/>
                                        }
                                    <div className='d-flex flex-column text-center'>
                                        {row.name}
                                        <small>({
                                            row.search === "AQI"?
                                            "Index"
                                            :row.search.startsWith("Dust")?
                                            "µg/m3"
                                            :row.search === "Temperature" || row.search === "IndoorTemp"?
                                            "°C"
                                            :row.search === "Humidity" || row.search === "IndoorHumidity"?
                                            "%"
                                            :row.search === "Rain"?
                                            "mm"
                                            :row.search === "WindSpeed"?
                                            "km/hr"
                                            :row.search === "Gust"?
                                            "km/hr"
                                            :row.search === "UV"?
                                            "µW/cm2"
                                            :row.search === "UVI"?
                                            "index"
                                            :row.search === "Illuminance"?
                                            "klux"
                                            :row.search === "WindDirection"?
                                            "°"
                                            :row.search === "HeatIndex"?
                                            "index"
                                            :null
                                    })</small>
                                    </div>
                                    {row.value}
                                </label> 
                            )}
                        </div>
                    </React.Fragment>
                :null}
                </div>
                {/* <div className={'dropshadow bg-white rounded-1 markerPopup col-xl-4 col-lg-5 col-md-6 col-sm-5 col-8 p-0'+(popupData?' d-inline':' d-none')}>
                    {popupData?
                    <React.Fragment>
                        <div className='d-flex justify-content-between align-items-center dropshadow'>
                            <h5 className='m-0 px-3 text-truncate'>{popupData.name}</h5>
                            <div className='btn btn-light closeBtn' onClick={()=>setPopupData(null)}><i className={'fa fa-times'}></i></div>
                        </div>
                        {popupData.created?
                            <div
                            className="col-12 bg-dark text-light text-center"
                            style={{ fontSize: "12px" }}
                            >
                                Last Update:{" "}
                                {convertToDate(popupData.created)}
                            </div>
                            :null
                        }
                        <div className='row m-0 pb-3' style={{overflow:"auto",height:"calc(100% - 70px)"}}>
                        {popupData.list.map((row,keys)=>
                            <div key={keys} className="col-md-6 mt-3">
                                <div className="h-100 d-flex flex-column align-items-center align- m-0 dropshadow rounded-1 pt-4 pb-3">
                                    <div className="d-flex justify-content-center" style={{maxHeight:100}}>
                                        {row.search === "WindDirection"?
                                            // <div style={{height:}}>
                                                <CompassIcon value={row.value}/>
                                            // </div>
                                        :
                                            <img style={{width:100,height:100}} src={
                                                row.search === "Temperature" || row.search === "IndoorTemp"?
                                                TemperatureIcon
                                                :row.search === "Humidity" || row.search === "IndoorHumidity"?
                                                HumidityIcon
                                                :row.search === "Rain"?
                                                RainIcon
                                                :row.search === "WindSpeed"?
                                                AirIcon
                                                :row.search === "Gust"?
                                                GustIcon
                                                :row.search === "UV"?
                                                UVIcon
                                                :row.search === "UVI"?
                                                UVIIcon
                                                :row.search === "Illuminance"?
                                                IlluminanceIcon
                                                :null
                                            }/>
                                        }
                                    </div>
                                    <h4 className='position-relative my-2' style={{color: 
                                            row.search === "Temperature" || row.search === "IndoorTemp"?
                                            "red"
                                            :row.search === "Humidity" || row.search === "IndoorHumidity"?
                                            "rgb(25, 120, 215)"
                                            :row.search === "Rain"?
                                            "rgb(27, 117, 188)"
                                            :row.search === "WindSpeed"?
                                            "rgb(0, 85, 255)"
                                            :row.search === "Gust"?
                                            "rgb(255, 81, 0)"
                                            :row.search === "UV"?
                                            "rgb(255, 153, 0)"
                                            :row.search === "UVI"?
                                            "rgb(255, 153, 0)"
                                            :row.search === "Illuminance"?
                                            "rgb(255, 153, 0)"
                                            :row.search === "WindDirection"?
                                            "blue"
                                            :null
                                    }}>{row.value}<small><sup className='position-absolute' style={{top:5}}>{
                                        row.search === "Temperature" || row.search === "IndoorTemp"?
                                            "°C"
                                            :row.search === "Humidity" || row.search === "IndoorHumidity"?
                                            "%"
                                            :row.search === "Rain"?
                                            "mm"
                                            :row.search === "WindSpeed"?
                                            "km/hr"
                                            :row.search === "Gust"?
                                            "km/hr"
                                            :row.search === "UV"?
                                            "µW/cm2"
                                            :row.search === "UVI"?
                                            "index"
                                            :row.search === "Illuminance"?
                                            "klux"
                                            :row.search === "WindDirection"?
                                            "°"
                                            :null
                                    }</sup></small></h4>
                                    <h5 className="m-0">{row.name}</h5>
                                </div>
                            </div>
                        )}
                        </div>
                    </React.Fragment>
                    :null}
                </div> */}
                {popupCompare?
                <div className={'dropshadow bg-white rounded-1 popupCompare col-md-8 col-12 p-0'+(popupCompare?' d-inline':' d-none')} style={{ overflow: "hidden"}}>
                    <div className='d-flex justify-content-between align-items-center dropshadow'>
                        <h5 className='m-0 px-3 text-truncate'>{selectedLayer}</h5>
                        <div className='btn btn-light closeBtn' onClick={()=>setPopupCompare(null)}><i className={'fa fa-times'}></i></div>
                    </div>
                    <div className='d-flex justify-content-end mb-3 px-3 w-100'>
                        <div className='col-12'>
                            {locationList.length?
                                <Input label="Loactions" defaultValue={{multiRow:[0,1,2,3]}} options={locationList} onChange={(e)=>selectedLoaction(e)}/>
                            :null}
                        </div>
                    </div>
                    <div style={{ overflow: "hidden", height: "400px", margin: "0 20px 20px 0" }}>
                        {popupCompare?.Loader?
                        <Loader/>
                        :
                        popupCompare?.chartConfig && popupCompare?.chartData?
                        <ChartConfig chart={popupCompare.chartConfig}
                        data={popupCompare.chartData}
                        graphcolor="#1476bd" />
                        :<Loader/>
                        }
                    </div>
                </div>
                :null}
            </>
        :null}
    </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyA9osJ2QBSR6f9oBEZULavTAJzZxVccQMo",
})(WeatherMap);