import {
  Map,
  InfoWindow,
  Marker,
  Polyline,
  GoogleApiWrapper,
} from "google-maps-react";
import React, { Component } from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "../../scss/svgs.css";
import RangeSlider from "react-bootstrap-range-slider";
import ReactDOM from "react-dom";
import ReactSlider from "react-slider";
// import * as config from '../../config';
import * as functions from "../../functions";
import * as func from "../../functions/functions";
import $ from "jquery";
// import Loader from '../loader/loader'
import * as moment from "moment";
import { connect } from "react-redux";
import { getAqiByDevice } from "../../shared/services/events";
import Slider_play from "../../images/slider/play.svg";
import Slider_pause from "../../images/slider/pause.svg";
import Slider_fwd from "../../images/slider/forward.svg";
import Slider_rwd from "../../images/slider/backward.svg";
import Loader from "../../components/loader1";
// import { getEventsByLevel, getStatusByLevel, getEventsByDevice, getDeviceById } from '../../shared/services/events'
import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

export class OverviewMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hourlyData: [],
      dailyData: [],
      radioSelected: 1,
      sliderValue: 29,
      showingInfoWindow: false,
      initialFetch: false,
      activeMarker: {},
      selectedPlace: {
        markerData: {
          name: null,
        },
      },
      visible: true,
      bounds: null,
      mobileView: false,
      center: {
        lat: null,
        lng: null,
      },
      data: null,
      google: null,
      devices: null,
      events: null,
      interval: null,
      filterHub: [],
      loading: true,
      playType: "Stop",
      sliderIndex: true,
      changeDateIndex: null,
    };
    this.fetchPlaces = this.fetchPlaces.bind(this);
  }

  onMarkerClick = (props, marker, e) => {
    // if (this.state.mobileView) {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
      });
    // }
  };
  handleMouseOver = (props, marker, e) => {
    if (this.state.showingInfoWindow === false && this.state.mobileView === false) {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
    }
  };
  handleMouseExit = (e) => {

    if (this.state.showingInfoWindow && this.state.mobileView === false) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });

    if (functions.isMobile.any() == null) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }

    $(window).resize(() => {
      if (functions.isMobile.any() == null) {
        this.setState({
          mobileView: false,
        });
      } else {
        this.setState({
          mobileView: true,
        });
      }
    });

    let data = [];

    await Promise.all(
      this.props.aqiDevices.map(async (d) => {
        let res = await getAqiByDevice(d._id, "Atmosphere_AQI", "hourly");
        res.reverse();
        if (res) {
          data.push({
            deviceId: d._id,
            levelId: d.levelId,
            events: res,
            location: d.location,
          });
        } else {
          data.push({
            deviceId: d._id,
            levelId: d.levelId,
            events: [],
            location: d.location,
          });
        }
      })
    );

    let hourlyData = [];
    let hourlyIndex = 0;
    let created;
    let change;
    let location;
    let deviceValues = [];
    if (data.length > 0) {
      // data.map((d,index)=>{
      //     d.events.map((e,inex1)=>{
      //         e.created = ( e.max.created > e.min.created ) ? e.max.created : e.min.created;
      //     })
      // })
      /**
       *  First we sort created record of 11 different devices events of same time
       *  & push it into hourlyData
       */

      var i;
      for (i = 0; i < 30; i++) {
        var j;
        for (j = 0; j < data.length; j++) {
          created = data[j].events[i].created;
          location = data[j].location;
          if (j == 0) {
            change = data[j].events[i].created;
            location = data[j].location;
          } else if (change < created) {
            change = data[j].events[i].created;
            location = data[j].location;
          }
        }
        hourlyData.push({
          created: change,
          deviceValues: [
            {
              deviceId: data[0].deviceId,
              levelId: data[0].levelId,
              value: data[0].events[i].avg,
              location: data[0].location,
            },
          ],
        });
      }

      hourlyData.map((h, index) => {
        data.map((d, index1) => {
          if (index1 > 0) {
            h.deviceValues.push({
              deviceId: d.deviceId,
              levelId: d.levelId,
              value: d.events[index].avg,
              location: d.location,
            });
          }
        });
      });

      let check2 = true;
      hourlyData.map((h, index) => {
        let propDate = hourlyData[index].created;
        let currDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");
        /**
         * comparing dates to seperate historical data and predicted data
         */
        if (propDate > currDate && check2) {
          hourlyIndex = index;
          check2 = false;
        }
      });
    }


    let data1 = [];

    await Promise.all(
      this.props.aqiDevices.map(async (d) => {
        let res = await getAqiByDevice(d._id, "Atmosphere_AQI", "daily");
        res.reverse();
        if (res) {
          data1.push({
            deviceId: d._id,
            levelId: d.levelId,
            events: res,
          });
        } else {
          data1.push({
            deviceId: d._id,
            levelId: d.levelId,
            events: [],
          });
        }
      })
    );

    let dailyData = [];
    if (data1.length > 0) {
      data1[0].events.map((d) => {
        dailyData.push({
          created: d.created,
          deviceValues: [
            {
              deviceId: data1[0].deviceId,
              levelId: data1[0].levelId,
              value: d.avg,
            },
          ],
        });
      });
      dailyData.map((h, index) => {
        data1.map((d, index1) => {
          if (index1 > 0) {
            h.deviceValues.push({
              deviceId: d.deviceId,
              levelId: d.levelId,
              value: d.events[index].avg,
            });
          }
        });
      });
    }

    this.setState({
      hourlyData,
      dailyData,
      loading: false,
      sliderValue: hourlyIndex - 1,
      sliderIndex: false,
      changeDateIndex: hourlyIndex - 1,
    });
  }

  componentWillMount() {
    this.state.google = this.props.google;
    this.state.bounds = new this.props.google.maps.LatLngBounds();
    // this.setState({
    //     filterHub: this.props.filterHub
    // })

    // var points = [
    //     {
    //         lat: 24.861751,
    //         lng: 67.070533
    //     }
    // ]
    // this.props.aqiDevices.map((value) => {
    //   value.level[0].metadata
    //     ? this.state.bounds.extend(value.level[0].metadata.coordinates)
    //     : null;
    // });
  }

  // async componentWillReceiveProps(props) {

  // if (props.mqttReducer) {
  //     let dev = await getDeviceById(props.mqttReducer.currentHomeEvent.deviceID)

  //     this.state.filterHub.map((h, i) => {
  //         if (h._id === dev.levelId) {
  //             if (dev.type === 'Gps') {

  //                 let temp = h;
  //                 temp.metadata.coordinates.lat = props.mqttReducer.currentHomeEvent.lat;
  //                 temp.metadata.coordinates.lng = props.mqttReducer.currentHomeEvent.lng;

  //                 this.state.filterHub[i] = temp;
  //             }
  //         }
  //     })
  // }
  // }

  // getPositions = async () => {

  // var a = 0; var found = false;
  // if (this.props.data.levels) {

  //     if (this.props.data.levels.length >= 1) {

  //         for (let x = 0; x < this.props.data.levels.length; x++) {

  //             if (this.props.data.levels[x].tags) {

  //                 if (this.props.data.levels[x].tags.includes('Atmosphere') || this.props.data.levels[x].tags.includes('Kitchen') || this.props.data.levels[x].tags.includes('Agri') || this.props.data.levels[x].tags.includes('Fuel') || this.props.data.levels[x].tags.includes('Weather')) {

  //                     if (this.props.data.levels[x].metadata) {

  //                         if (this.props.data.levels[x].metadata.coordinates) {

  //                             if (this.props.data.levels[x].metadata.coordinates.lat && this.props.data.levels[x].metadata.coordinates.lng) {

  //                                 var pos = {
  //                                     lat: this.props.data.levels[x].metadata.coordinates.lat,
  //                                     lng: this.props.data.levels[x].metadata.coordinates.lng
  //                                 }
  //                                 this.state.bounds.extend(pos)

  //                             }
  //                         }

  //                     }
  //                 }
  //             }

  //         }

  //     }

  // }

  // }

  async fetchPlaces(mapProps, map) {
    // await this.getPositions();
    // map.fitBounds(this.state.bounds);
    // map.panToBounds(this.state.bounds);
    // this.setState({
    //     visible: true
    // })
  }

  onRadioBtnClick(value) {

    this.setState(
      {
        sliderValue: value === 1 ? this.state.changeDateIndex : 14,
        radioSelected: value,
        playType: "Stop",
        sliderIndex: false,
      },
      () => {
        this.Play();
      }
    );
  }

  setMarker = (level) => {
    if (this.state.radioSelected === 1) {
      if (this.state.hourlyData.length > 0) {
        let url = "";

        let index = this.state.sliderValue;
        if (!this.state.hourlyData[index]) {
          index = 29;
        }

        this.state.hourlyData[index].deviceValues.map((d) => {
          if (level._id === d.levelId) {
            let aqiValue = d.value;

            if (aqiValue !== -1) {
              let Colorvalue = parseInt(aqiValue),
                tableColor = "",
                red = 0,
                green = 255,
                blue = 0;
              if (Colorvalue > 25 && Colorvalue <= 50) {
                var per = ((Colorvalue - 25) / 25) * 100;
                red = (255 * per) / 100;
                green = 255;
                blue = 0;
              } else if (Colorvalue > 50 && Colorvalue <= 100) {
                var per = ((Colorvalue - 50) / 50) * 100;
                red = 255;
                green = 255 - (102 * per) / 100;
                blue = 0;
              } else if (Colorvalue > 100 && Colorvalue <= 200) {
                red = 255;
                green = 102 - (102 * (Colorvalue - 100)) / 100;
                blue = 0;
              } else if (Colorvalue > 200 && Colorvalue <= 300) {
                red = 200 - (55 * (Colorvalue - 200)) / 100;
                green = 0;
                blue = (255 * (Colorvalue - 225)) / 100;
              } else if (Colorvalue > 300) {
                red = 145;
                green = 0;
                blue = 191;
              }
              tableColor = "rgb(" + red + "," + green + "," + blue + ")";
              url = this.svgMarker(tableColor, aqiValue);
            } else {
              url = this.svgMarker("grey", "OFF");
            }
            // if (aqiValue !== -1) {
            //     if (aqiValue >= 0 && aqiValue <= 50) {
            //         url = this.svgMarker('#10ff00', aqiValue);
            //     } else if (aqiValue >= 51 && aqiValue <= 100) {
            //         url = this.svgMarker('#fff200', aqiValue);
            //     } else if (aqiValue >= 101 && aqiValue <= 200) {
            //         url = this.svgMarker('#ffae00', aqiValue);
            //     } else if (aqiValue >= 201 && aqiValue <= 300) {
            //         url = this.svgMarker('#ff0000', aqiValue);
            //     } else if (aqiValue >= 301) {
            //         url = this.svgMarker('#bf00ff', aqiValue);
            //     }
            // }else{
            //     url = this.svgMarker('#6b6b6b', aqiValue);
            // }
            // if (aqiValue !== -1) {
            //     if (aqiValue >= 0 && aqiValue <= 50) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=000&background=10ff00&name=" + aqiValue;
            //     } else if (aqiValue >= 51 && aqiValue <= 100) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=000&background=fff200&name=" + aqiValue;
            //     } else if (aqiValue >= 101 && aqiValue <= 200) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=000&background=ffae00&name=" + aqiValue;
            //     } else if (aqiValue >= 201 && aqiValue <= 300) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=fff&background=ff0000&name=" + aqiValue;
            //     } else if (aqiValue >= 301) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=fff&background=bf00ff&name=" + aqiValue;
            //     }
            // }else{
            //     url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.2&length=7&color=fff&background=6b6b6b&name=Offline";
            // }
          }
        });
        return url;
      } else {
        // return "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=000&background=10ff00&name=0";
        // return this.svgMarker('rgb(0,255,0)', '0');

        return this.svgMarker("#10ff00", "0");
      }
    } else if (this.state.radioSelected === 2) {
      if (this.state.dailyData.length > 0) {
        let url = "";
        let index = this.state.sliderValue;
        if (!this.state.dailyData[index]) {
          index = 14;
        }
        this.state.dailyData[index].deviceValues.map((d) => {
          if (level._id === d.levelId) {
            let aqiValue = d.value;

            if (aqiValue !== -1) {
              let Colorvalue = parseInt(aqiValue),
                tableColor = "",
                red = 0,
                green = 255,
                blue = 0;
              if (Colorvalue > 25 && Colorvalue <= 50) {
                var per = ((Colorvalue - 25) / 25) * 100;
                red = (255 * per) / 100;
                green = 255;
                blue = 0;
              } else if (Colorvalue > 50 && Colorvalue <= 100) {
                var per = ((Colorvalue - 50) / 50) * 100;
                red = 255;
                green = 255 - (102 * per) / 100;
                blue = 0;
              } else if (Colorvalue > 100 && Colorvalue <= 200) {
                red = 255;
                green = 102 - (102 * (Colorvalue - 100)) / 100;
                blue = 0;
              } else if (Colorvalue > 200 && Colorvalue <= 300) {
                red = 200 - (55 * (Colorvalue - 200)) / 100;
                green = 0;
                blue = (255 * (Colorvalue - 225)) / 100;
              } else if (Colorvalue > 300) {
                red = 145;
                green = 0;
                blue = 191;
              }
              tableColor = "rgb(" + red + "," + green + "," + blue + ")";
              url = this.svgMarker(tableColor, aqiValue);
            } else {
              url = this.svgMarker("grey", "OFF");
            }
            // if (aqiValue !== -1) {
            //     if (aqiValue >= 0 && aqiValue <= 50) {
            //         url = this.svgMarker('#10ff00', aqiValue);
            //     } else if (aqiValue >= 51 && aqiValue <= 100) {
            //         url = this.svgMarker('#fff200', aqiValue);
            //     } else if (aqiValue >= 101 && aqiValue <= 200) {
            //         url = this.svgMarker('#ffae00', aqiValue);
            //     } else if (aqiValue >= 201 && aqiValue <= 300) {
            //         url = this.svgMarker('#ff0000', aqiValue);
            //     } else if (aqiValue >= 301) {
            //         url = this.svgMarker('#bf00ff', aqiValue);
            //     }
            // }else{
            //     url = this.svgMarker('#6b6b6b', aqiValue);
            // }
            // if (aqiValue !== -1) {
            //     if (aqiValue >= 0 && aqiValue <= 50) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=000&background=10ff00&name=" + aqiValue;
            //     } else if (aqiValue >= 51 && aqiValue <= 100) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=000&background=fff200&name=" + aqiValue;
            //     } else if (aqiValue >= 101 && aqiValue <= 200) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=000&background=ffae00&name=" + aqiValue;
            //     } else if (aqiValue >= 201 && aqiValue <= 300) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=fff&background=ff0000&name=" + aqiValue;
            //     } else if (aqiValue >= 301) {
            //         url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=fff&background=bf00ff&name=" + aqiValue;
            //     }
            // }else{
            //     url = "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=fff&background=6b6b6b&name=Offline";
            // }
          }
        });
        return url;
      } else {
        // return "https://ui-avatars.com/api/?rounded=true&size=40&font-size=0.4&length=4&color=000&background=10ff00&name=0";
        // return this.svgMarker('rgb(0,255,0)', '0');
        return this.svgMarker("#10ff00", "0");
      }
    }
  };

  svgMarker = (color, value) => {
    let fontSize;
    if (value === "-1") {
      fontSize = "6500px";
    } else {
      fontSize = "5000px";
    }
    return (
      '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="40px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 16746 24233" xmlns:xlink="http://www.w3.org/1999/xlink">' +
      "<defs>" +
      '<linearGradient id="marker_id0" gradientUnits="userSpaceOnUse" x1="8502.77" y1="874.807" x2="8502.77" y2="24687.1">' +
      '<stop offset="0" style="stop-opacity:0.588235; stop-color:white"/>' +
      '<stop offset="0.341176" style="stop-opacity:0; stop-color:white"/>' +
      '<stop offset="0.580392" style="stop-opacity:0; stop-color:white"/>' +
      '<stop offset="1" style="stop-opacity:0.54902; stop-color:black"/>' +
      "</linearGradient>" +
      '<radialGradient id="marker_id1" gradientUnits="userSpaceOnUse" cx="8658" cy="7859" r="7500" fx="8758" fy="7859">' +
      '<stop offset="0" style="stop-opacity:1; stop-color:white"/>' +
      '<stop offset="0.7" style="stop-opacity:1; stop-color:white"/>' +
      '<stop offset="1" style="stop-opacity:1; stop-color:#B3B3B3"/>' +
      "</radialGradient>" +
      "</defs>" +
      "<g>" +
      '<path fill="' +
      color +
      '" d="M8373 0c4624,0 8373,3749 8373,8373 0,3614 -2289,6693 -5497,7866l-2876 7994 -2876 -7994c-3208,-1173 -5497,-4252 -5497,-7866 0,-4624 3749,-8373 8373,-8373z"/>' +
      '<circle fill="url(#marker_id1)" cx="8373" cy="8373" r="6418"/>' +
      '<text dominant-baseline="middle" text-anchor="middle" x="50%" y="35%" fill="black" style="font-weight:bold;font-size:' +
      fontSize +
      ';font-family:Arial">' +
      value +
      "</text>" +
      // '<path fill="url(#marker_id0)" d="M8373 0c4624,0 8373,3749 8373,8373 0,3614 -2289,6693 -5497,7866l-2876 7994 -2876 -7994c-3208,-1173 -5497,-4252 -5497,-7866 0,-4624 3749,-8373 8373,-8373zm0 1956c3544,0 6418,2873 6418,6417 0,3544 -2874,6418 -6418,6418 -3544,0 -6417,-2874 -6417,-6418 0,-3544 2873,-6417 6417,-6417z"/>'+
      "</g>" +
      "</svg>"
    );
  };

  Play = () => {
    if (this.state.radioSelected === 1) {
      var mySlider;
      let val = 1;
      if (this.state.sliderValue == 29) {
        val = 0;
      }
      let propDate =
        this.state.hourlyData[parseInt(this.state.sliderValue) + val].created;
      let currDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");
      let sliderIndex = true;
      if (propDate < currDate) {
        sliderIndex = false;
      } else {
        sliderIndex = true;
      }

      if (this.state.playType === "Play") {
        mySlider = setTimeout(() => {
          if (this.state.sliderValue < 29) {
            this.setState(
              {
                sliderValue: parseInt(this.state.sliderValue) + 1,
                sliderIndex,
              },
              () => {
                this.Play();
              }
            );
          } else {
            this.setState({
              playType: "Stop",
            });
          }
        }, 2000);
      }
      if (this.state.playType === "Stop") {
        clearTimeout(mySlider);
      }
    } else if (this.state.radioSelected === 2) {
      var mySlider;

      if (this.state.playType === "Play") {
        mySlider = setTimeout(() => {
          if (this.state.sliderValue < 14) {
            this.setState(
              {
                sliderValue: parseInt(this.state.sliderValue) + 1,
              },
              () => {
                this.Play();
              }
            );
          } else {
            this.setState({
              playType: "Stop",
            });
          }
        }, 2000);
      }
      if (this.state.playType === "Stop") {
        clearTimeout(mySlider);
      }
    }
  };

  handlePlay = () => {
    if (this.state.radioSelected === 1) {
      if (this.state.playType === "Play") {
        this.setState({ playType: "Stop" }, () => {
          this.Play();
        });
      } else if (this.state.playType === "Stop") {
        this.setState(
          { playType: "Play", sliderValue: 0, sliderIndex: false },
          () => {
            this.Play();
          }
        );
      }
    } else if (this.state.radioSelected === 2) {
      if (this.state.playType === "Play") {
        this.setState({ playType: "Stop" }, () => {
          this.Play();
        });
      } else if (this.state.playType === "Stop") {
        this.setState({ playType: "Play", sliderValue: 0 }, () => {
          this.Play();
        });
      }
    }
  };

  handleRwd = () => {
    if (this.state.sliderValue > 0) {
      let propDate =
        this.state.hourlyData[parseInt(this.state.sliderValue) - 1].created;
      let currDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");

      let sliderIndex = true;
      if (propDate < currDate) {
        sliderIndex = false;
      } else {
        sliderIndex = true;
      }

      this.setState({
        sliderValue: parseInt(this.state.sliderValue) - 1,
        sliderIndex,
      });
    }
  };

  handleFwd = () => {
    if (this.state.radioSelected === 1) {
      if (this.state.sliderValue < 29) {
        let propDate =
          this.state.hourlyData[parseInt(this.state.sliderValue) + 1].created;
        let currDate = moment().format("YYYY-MM-DD[T]HH:mm:ss");

        let sliderIndex = true;
        if (propDate < currDate) {
          sliderIndex = false;
        } else {
          sliderIndex = true;
        }

        this.setState({
          sliderValue: parseInt(this.state.sliderValue) + 1,
          sliderIndex,
        });
      }
    } else if (this.state.radioSelected === 2) {
      if (this.state.sliderValue < 14) {
        this.setState({
          sliderValue: parseInt(this.state.sliderValue) + 1,
        });
      }
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Loader />
        </div>
      );
    } else {
      return (
        <div
          className="m-0 bg-info"
          style={
            window.screen.width < 700 ? { height: "auto" } : { height: "500px" }
          }
        >
          <div className="row m-0 bg-light">
            <div
              className="col-12 bg-dark text-light text-center"
              style={{ fontSize: "12px" }}
            >
              <b>
                {this.state.radioSelected === 1 ? "Hourly" : "Daily"} Update:
              </b>{" "}
              {func.convertToDate(
                this.state.hourlyData[this.state.changeDateIndex].created
              )}
            </div>
            <div
              className="col-12 m-0 p-0 bg-success"
              style={{ height: "392px" }}
            >
              <Map
                google={this.props.google}
                initialCenter={this.props.location}
                maxZoom={19}
                onClick={this.onMapClicked}
                zoom={11}
                onReady={this.fetchPlaces}
                visible={this.state.visible}
                mapTypeControl={false}
                streetViewControl={false}
              >
                {this.props.aqiDevices.map((value, index) => (
                  <Marker
                    title={value.level[0].name + " ("+value.hub[0].name+")"}
                    key={index}
                    name={value.level[0].name}
                    onClick={this.onMarkerClick}
                    // onMouseover={this.handleMouseOver
                    // } onMouseout={this.handleMouseExit
                    // }
                    markerData={{name:value.level[0].name,location:value.hub[0].name}}
                    position={
                      value.level[0].metadata
                        ? value.level[0].metadata.coordinates
                        : null
                    }
                    // icon={'data:image/svg+xml;utf8,' + this.setMarker(value.level[0])}
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8;base64," +
                        btoa(this.setMarker(value.level[0])),
                        scaledSize: new this.props.google.maps.Size(50,
                          50)
                    }}
                    // icon={{
                    //     // url: this.setMarker(value.level[0])
                    //     url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(this.setMarker(value.level[0])),
                    //     anchor: new google.maps.Point(5, 58)

                    // }}
                  />
                ))}
                <InfoWindow
                  marker={this.state.activeMarker}
                  visible={this.state.showingInfoWindow}
                >
                  <div>
                    <p style={{fontFamily: "arial", textAlign: "center", margin: 0,}}>
                      <b className="m-0">{this.state.selectedPlace.markerData.name}</b><br/>
                      <small style={{fontSize:10}}>({this.state.selectedPlace.markerData.location})</small>
                    </p>
                  </div>
                </InfoWindow>
              </Map>
              <div className="col-12 mt-1 text-center">
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#10ff00",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "white",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Good
                </span>
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#fff200",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "white",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Moderate
                </span>
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#ffae00",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "white",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Unhealthy
                </span>
                <br className="d-md-none" />
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#ff0000",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "white",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Very Unhealthy
                </span>
                <span
                  className="mx-1"
                  style={{
                    backgroundColor: "#bf00ff",
                    padding: "5px 10px",
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "white",
                    borderRadius: "50px",
                    lineHeight: "30px",
                  }}
                >
                  Hazardous
                </span>
              </div>
            </div>
            <div
              className="col-12 m-0 p-0 bg-light"
              style={
                window.screen.width < 700
                  ? { height: "auto" }
                  : { height: "85px" }
              }
            >
              <div className="row m-0 pl-md-3 pr-md-3 p-2 d-flex align-self-center">
                <div className="col-12 p-0">
                  <div className="row m-0">
                    <div className="col-lg-3 col-md-5 col-12 p-0 align-self-center">
                      {this.state.radioSelected === 1 ? (
                        <div>
                          <b>Hour:</b>{" "}
                          {this.state.hourlyData[this.state.sliderValue]
                            ? func.convertToDate(
                                this.state.hourlyData[this.state.sliderValue]
                                  .created,
                                "compTime"
                              )
                            : func.convertToDate(
                                this.state.hourlyData[29].created,
                                "comp"
                              )}
                        </div>
                      ) : (
                        <div>
                          <b>Day:</b>{" "}
                          {this.state.dailyData[this.state.sliderValue]
                            ? func.convertToDate(
                                this.state.dailyData[this.state.sliderValue]
                                  .created,
                                "compDate"
                              )
                            : func.convertToDate(
                                this.state.dailyData[14].created,
                                "compDate"
                              )}
                        </div>
                      )}
                    </div>
                    <div className="col-md col-12 p-0">
                      <RangeSlider
                        value={this.state.sliderValue}
                        onChange={(changeEvent) => {
                          let propDate =
                            this.state.hourlyData[changeEvent.target.value]
                              .created;
                          let currDate = moment().format(
                            "YYYY-MM-DD[T]HH:mm:ss"
                          );

                          let sliderIndex = true;
                          if (propDate < currDate) {
                            sliderIndex = false;
                          } else {
                            sliderIndex = true;
                          }

                          this.setState({
                            sliderValue: changeEvent.target.value,
                            sliderIndex,
                          });
                        }}
                        min={0}
                        max={this.state.radioSelected === 1 ? 29 : 14}
                        tooltip="off"
                        variant={this.state.sliderIndex ? "danger" : "primary"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6 p-0 d-flex" style={{ height: "30px" }}>
                  <div className="align-self-center">
                    <img
                      height="30px"
                      width={50}
                      src={Slider_rwd}
                      onClick={() => this.handleRwd()}
                    />
                  </div>
                  <div className="align-self-center">
                    {this.state.playType === "Stop" ? (
                      <img
                        height="30px"
                        width={50}
                        className="mx-2"
                        src={Slider_play}
                        onClick={() => this.handlePlay()}
                      />
                    ) : (
                      <img
                        height="30px"
                        width={50}
                        className="mx-2"
                        src={Slider_pause}
                        onClick={() => this.handlePlay()}
                      />
                    )}
                  </div>
                  <div className="align-self-center">
                    <img
                      height="30px"
                      width={50}
                      src={Slider_fwd}
                      onClick={() => this.handleFwd()}
                    />
                  </div>
                </div>
                <div className="col-6 p-0 d-flex justify-content-end">
                  <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup
                      className="float-right"
                      aria-label="First group"
                    >
                      <Button
                        style={{
                          padding: "0.275rem 0.75rem",
                          fontSize: "15px",
                          height: "30px",
                        }}
                        color="outline-secondary"
                        onClick={() => this.onRadioBtnClick(1)}
                        active={this.state.radioSelected === 1}
                      >
                        Hourly
                      </Button>
                      <Button
                        style={{
                          padding: "0.275rem 0.75rem",
                          fontSize: "15px",
                          height: "30px",
                        }}
                        color="outline-secondary"
                        onClick={() => this.onRadioBtnClick(2)}
                        active={this.state.radioSelected === 2}
                      >
                        Daily
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyA9osJ2QBSR6f9oBEZULavTAJzZxVccQMo",
})(OverviewMap);
