import axios from '../utilities/axios';
import cookies from '../utilities/cookies';
import trees from '../utilities/trees';

export let getAqiLevels = async (levelId, type) => {
    try {
        let response = await axios.get('/levels/' + levelId + '/levels');
        return response.data;
    } catch (err) {
        return 'error';
    }
}

let getHierarchy = async (levelId) => {
    try {
        console.log("Fetching Levels")
        let token = cookies.getCookie('token');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/levels/' + levelId + '/hierarchy');
        let hierarchy = trees.flatenHierarchy(response.data);
        console.log("Revieved Levels")
        return [ response.data, ...hierarchy ];
    } catch(err) {
        return 'Errror';
    }
}

export default {
    getHierarchy
}