import moment from 'moment'
import React, { useLayoutEffect, useState } from 'react'
import { useEffect } from 'react'
import './weatherSummary.css'
function WeatherSummary(props) {
  const [data, setData] = useState(null)
  const [index, setIndex] = useState(0)
  const [currentCoords, setCurrentCoords] = useState(null)
  useLayoutEffect(() => {
    let coords = null
    navigator.geolocation.getCurrentPosition(function(position) {
        coords = {lat: 0, lng: 0}
        coords.lat = position.coords.latitude
        coords.lat = parseInt(coords.lat.toFixed(2))

        coords.lng = position.coords.longitude
        coords.lng = parseInt(coords.lng.toFixed(2))
    });
    setCurrentCoords(coords)
    fetchData(coords);
  }, [])
  
  const fetchData = async (coords) => {
    // window.navigator.geolocation.getCurrentPosition(console.log, console.log);
    // const options1 = {
    //     method: 'GET',
    //     url: 'https://weatherapi-com.p.rapidapi.com/forecast.json',
    //     params: {q: (coords? coords.lat+','+coords.lng:'24.8607,67.0658'), days: '3'},
    //     headers: {
    //       'X-RapidAPI-Key': '9ea5c43bb0mshc9fde0ba82c70fcp1a462ejsn3779a2cd1f4e',
    //       'X-RapidAPI-Host': 'weatherapi-com.p.rapidapi.com'
    //     }
    // };
    // const options2 = {
    //     method: 'GET',
    //     url: 'https://www.ncei.noaa.gov/cdo-web/api/v2/datatypes?stationid=GHCND%3APKM00041780',
    //     headers: {
    //         token: 'GWCkjuoCCwbTBylbqNKseRcPAMZRjcUe'
    //     }
    // };
    // const options3 = {
    //     method: 'GET',
    //     url: 'https://www.ncei.noaa.gov/cdo-web/api/v2/data?datasetid=GSOY&stationid=GHCND%3APKM00041780&units=metric&startdate=2015-01-01&enddate=2022-10-31',
    //     headers: {
    //         token: 'GWCkjuoCCwbTBylbqNKseRcPAMZRjcUe'
    //     }
    // };
    let coords2 = "lat=24.86&lng=67.06"
    if(coords){
      coords2 =  "lat="+coords.lat+"&lng="+coords.lng
    }
    let data1 = await apiFetch("https://gateway.s5.ottomatically.com/api/v1/forecast?"+coords2)
    // let data2 = await apiFetch(options2)
    // let data3 = await apiFetch(options3)
    // let newData = []
    // data3.results.map(row => {
    //   data2.results.map(row1 => {
    //     if(row.datatype === row1.id){
    //       newData.push({...row,...row1})
    //     }
    //   })
    // })
    setData(data1)
  }

  useEffect(() => {
    fetchData(props.data?props.data.coords:currentCoords);
  }, [props.data])
  const getColor = () => {
    if (data) {
      let heatIValue = data.current.feelslike_c
      let heatR = 0,
        heatG = 255,
        heatB = 0;
      if (heatIValue <= 31) {
        heatR = 240;
        heatG = 210;
        heatB = 90;
      } else if (heatIValue > 31 && heatIValue <= 38) {
        var per = ((heatIValue - 31) / 7) * 100;
        heatR = 240;
        heatG = 240;
        heatB = 90 - (90 * per) / 100;
      } else if (heatIValue > 38 && heatIValue <= 51) {
        var per = ((heatIValue - 38) / 13) * 100;
        heatR = 240;
        heatG = 240 - (138 * per) / 100;
        heatB = 0;
      } else if (heatIValue > 51) {
        var per;
        if (heatIValue <= 60) {
          per = ((heatIValue - 51) / 9) * 100;
        } else {
          per = 100;
        }
        heatR = 240;
        heatG = 102 - (102 * per) / 100;
        heatB = 0;
      }
      let heatColor = "rgb(" + heatR + "," + heatG + "," + heatB + ")";
      // var heatColorbr = "rgba(" + heatR + "," + heatG + "," + heatB + ",0.5)";
      // heatIValue = heatIValue.toFixed(1);
      return heatColor;
    }
  }
  
  const apiFetch = async (API) => {
    const axios = require("axios");
    try {
      let response = await axios.request(API)
      return response.data
    } catch (error) {
      // return error
    }
  }
  
  return (
    data?
    <div className='weatherSummaryCard'>
      {/* <div className="container"> */}
        {!index?
            // {moment(data.current.last_updated).format('dddd')}
            <div className='row m-0 justify-content-center h-100 align-items-center'>
              <h2 className='col-12 moreBtn' onClick={()=>setIndex(1)}>more <i className='fa fa-angle-right ml-2'></i></h2>
              <div className='col-xl-3 col-lg-3 col-md-4 col-12 localState'>
                <div className='row m-0 align-items-center'>
                  <div className='col-lg-3 col-md-4 col-1 px-lg-3 p-0 weatherStatus'>
                    {data.current.condition.code === "1000"?
                      <img src={data.current.condition.icon} alt={data.current.condition.code}/>
                    : <i className="fas fa-moon"></i>
                    }
                  </div>
                  <div className='col-lg-9 col-md-8 col-3 weatherStatus'>
                    <h1>{data.current.condition.text}</h1>
                  </div>
                  <div className='col-lg-3 col-md-4 col-1 px-lg-3 p-0 text-center weatherStatus'>
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <div className='col-lg-9 col-md-8 col-7'>
                    <div className='location'>{(props.data?<React.Fragment>{props.data.name}<br/></React.Fragment>:"")}{data.location.name+" / "+data.location.country}</div>
                  </div>
                </div>
              </div>
              <div className='col-xl-5 col-lg-5 col-md-5 col-sm-7 col-9 px-md-3 p-0'>
                <div className='row m-0 align-items-center justify-content-center'>
                  <div className='px-3 d-flex'>
                    <div className='tempArea'>
                      <h1>{data.current.temp_c.toFixed(0)}°</h1>
                      <h2>Temperature</h2>
                    </div>
                    <div className='seperator'></div>
                    <div className='tempArea'>
                      <h2>Humidity</h2>
                      <h1>{data.current.humidity+"%"}</h1>
                    </div>
                  </div>
                  <div className='feelLikeCont'>
                    <div className='feelLike' style={{backgroundColor: getColor()}}>
                      <h1>{data.current.feelslike_c.toFixed(0)}°</h1>
                    </div>
                    <h2>Feel Like</h2>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-3 col-sm-5 col-3 px-md-3 p-0 pl-2 mt-1 align-self-center'>
                <table className='m-0'>
                  <tr>
                    <td className='weatherStatus d-table-cell p-0 border-0 text-left'>
                      <i className="fas fa-wind mr-1 d-sm-none d-inline"></i>
                      <h1 className='d-none d-sm-inline'>Wind Speed:</h1>
                    </td>
                    <td className='weatherStatus d-table-cell p-0 border-0 text-left'><h1><b>{data.current.wind_mph}<small> mph</small></b></h1></td>
                  </tr>
                  <tr>
                    <td className='weatherStatus d-table-cell p-0 border-0 text-left'>
                      <i className="fas fa-compass mr-1 d-sm-none d-inline"></i>
                      <h1 className='d-none d-sm-inline'>Wind Direction:</h1>
                    </td>
                    <td className='weatherStatus d-table-cell p-0 border-0 text-left'><h1><b>{data.current.wind_dir}</b></h1></td>
                  </tr>
                </table>
              </div>
            </div>
        :
          <div className='row h-100 align-items-center justify-content-center m-0'>
            <div className='col-12 moreBtn text-left' style={{cursor: 'pointer'}} onClick={()=>setIndex(0)}><i className='fa fa-angle-left mr-2'></i>back</div>
            {data.forecast.forecastday.map((row,key) =>
              <div key={key} className='col-xl-3 col-4 localState px-md-4 px-sm-2 p-0'>
                <div className='row m-0 justify-content-center align-items-center'>
                  <div className='col-lg-3 col-md-4 col-3 px-lg-3 p-0 weatherStatus'>
                    {row.day.condition.code === "1000"?
                      <img src={row.day.condition.icon} alt={row.day.condition.code}/>
                    : <i className="fas fa-moon"></i>
                    }
                  </div>
                  <div className='col-lg-9 col-md-8 col-8 weatherStatus text-right'>
                    <h1>{row.day.condition.text}</h1>
                  </div>
                  <div className='col-11 tempArea'>
                    <h2 className='text-left'>Temperature</h2>
                  </div>
                  <div className='col-5'>
                    <h1 className='text-left location m-0 text-white'>min: {row.day.mintemp_c.toFixed(0)}°</h1>
                  </div>
                  <div className='col-6'>
                    <h1 className='text-right location m-0 text-white'>max: {row.day.maxtemp_c.toFixed(0)}°</h1>
                  </div>
                  <div className='col-11 tempArea'>
                    <h2>{moment(row.date).format('dddd')}</h2>
                  </div>
                </div>
              </div>
              // <div className='col-lg-3' key={key}>
              //   <div className={'more_weather d-flex flex-column justify-content-between align-items-center'+(key === 0?' border-0':'')}>
              //     <div className='d-flex w-100 justify-content-between'>
              //       <h2 className=''>{moment(row.date).format('dddd')}</h2>
              //       <h2 className='moreBtn'>{row.day.condition.text}</h2>
              //     </div>
              //     <img className='py-lg-2' src={row.day.condition.icon} alt={row.day.condition.code}/>
              //     <h1>{row.day.mintemp_c.toFixed(0)+"° / "+row.day.maxtemp_c.toFixed(0)+"°"}</h1>
              //   </div>
              // </div>
            )}
          </div>
        }
      {/* </div> */}
    </div>
    :null
  )
}

export default WeatherSummary