// import React from 'react';
import React, { useState } from 'react';

import { Carousel } from 'react-bootstrap'

const Description = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="gradient-primary1 p-5 my-0 text-light" style={{ textAlign: 'center', fontSize: '16px'}}>
            <h1 className="AQIHeading" style={{ marginBottom: '20px' }}>Air Quality Monitoring Pakistan</h1>
            <Carousel activeIndex={index} onSelect={handleSelect} controls={false}>
                <Carousel.Item className="pb-5">
                    <q>Air quality index (AQI) is used to determine how polluted the air currently is or how polluted it is forecast to become.</q>
                    <p className="author text-light">- wikipedia</p>
                </Carousel.Item>
                <Carousel.Item className="pb-5">
                    <q>Every year, around 7 million deaths occur due to exposure from air pollution and 9 out of 10 people worldwide breathe polluted air.</q>
                    <p className="author text-light">- World Health Organization</p>
                </Carousel.Item>
                <Carousel.Item className="pb-5">
                    <q>In South Asia, Indian and Pakistani cities again dominate the world’s most polluted cities for PM2.5 in 2019.</q>
                    <p className="author text-light">- 2019 World Air Quality Report</p>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default Description;