import React, { Component } from "react";
import * as moment from "moment";

import { Illuminance } from "../../components/weather/illuminance";
import { UV } from "../../components/weather/uv";
import Gust from "../../components/weather/gust";
import Wind from "../../components/weather/wind";
import Humidity from "../../components/weather/humidity";
import FeelsLike from "../../components/weather/feelsLike";
import Rain from "../../components/weather/rain";
import Temp from "../../components/weather/temperature";
import Compass from "../../components/weather/compass";
import Loader from "../../components/loader";

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      indication: true,
    };
    this.mouse_moving = this.mouse_moving.bind(this);
  }

  async loadData() {
    if (this.props.indication !== this.state.indication) {
      let indication = !this.state.indication;
      this.setState({
        data: this.props.data,
        indication,
      });
    }
  }

  mouse_moving = (e) => {
    //   console.log("Mouse is moving",e.screenX)
  };

  render() {
    this.loadData();
    return (
      <div className="container my-sm-5 my-2" onMouseMove={this.mouse_moving}>
        <div className="row">
          {/* <div className="col-6">
            <div className="row p-md-3 p-sm-2 p-1 pl-2 h-100">
              <div className="col-6 p-0 pr-md-3 pr-sm-2 pr-1 pb-md-3 pb-sm-2 pb-1">
                {this.state.data !== null ? (
                  <Temp data={this.state.data.Temperature} />
                ) : (
                  <Loader />
                )}
              </div>
              <div className="col-6 p-0 pl-md-3 pl-sm-2 pl-1 pb-md-3 pb-sm-2 pb-1">
                {this.state.data !== null ? (
                  <Humidity data={this.state.data.Humidity} />
                ) : (
                  <Loader />
                )}
              </div>
              <div className="col-12 p-0 pt-md-3 pt-sm-2 pt-1">
                {this.state.data !== null ? (
                  <Rain data={this.state.data.Rain} />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div> */}
          <div className="col-6 d-flex p-md-3 p-sm-2 p-1 pr-2 trans-0-1">
            {this.state.data !== null ? (
              <FeelsLike
                humidity={this.state.data.Humidity}
                temperature={this.state.data.Temperature}
              />
            ) : (
              <Loader />
            )}
          </div>
          <div className="col-6 d-flex p-md-3 p-sm-2 p-1 pr-2 trans-0-1">
            {this.state.data !== null ? (
              <Rain data={this.state.data.Rain} />
            ) : (
              <Loader />
            )}
          </div>
          <div className="col-6 d-flex p-md-3 p-sm-2 p-1 pl-2 trans-0-1">
            {this.state.data !== null ? (
              <Compass data={this.state.data.WindDirection} />
            ) : (
              <Loader />
            )}
          </div>
          <div className="col-6">
            <div className="row p-md-3 p-sm-2 p-1 pr-2 h-100">
              <div className="col-12 p-0 pb-md-3 pb-sm-2 pb-1">
                {this.state.data !== null ? (
                  <Wind data={this.state.data.WindSpeed} />
                ) : (
                  <Loader />
                )}
              </div>
              <div className="col-12 p-0 pt-md-3 pt-sm-2 pt-1">
                {this.state.data !== null ? (
                  <Gust data={this.state.data.Gust} />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="col-6 p-md-3 p-sm-2 p-1 pl-2">
            {this.state.data !== null ? (
              <UV data={this.state.data.UV} />
            ) : (
              <Loader />
            )}
          </div>
          <div className="col-6 p-md-3 p-sm-2 p-1 pr-2">
            {this.state.data !== null ? (
              <Illuminance data={this.state.data.Illuminance} />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Weather;
