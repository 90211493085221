import { isNumeric, type } from 'jquery';
// import React from 'react';
import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner } from 'reactstrap';



class Calculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            op_hour:18,
            cars:500,
            watt:40,
            light:56,
            time:1.5,
            total:null,
            bg_cal:{
                p_spot:1, 
                time_per_light:null               
            },
            bg_cal_Per:{
                p_spot:1,                
            }
        };
    }
    componentDidMount(){
        this.handleTotal();
    }
    blurValid = async(event, fieldVal) => {
        if(!event.target.value > 0){
            event.target.value = null;
            this.setState({
                [fieldVal]: null
            })
        }else if(event.target.value == 0){
            this.setState({
                [fieldVal]: event.target.min
            })
        }
    }
    lessCondition = async(event, fieldVal) => {
        if(event.target.value == null || event.target.value < 0){
            event.target.value = null;
            this.setState({
                [fieldVal]: null
            })
        }else{
            if(event.target.value < 0){
                this.setState({
                    [fieldVal]: event.target.min
                })
            }
        }
    }
    handleOP = async (event) => {
        this.lessCondition(event, 'op_hour');
        if(isNumeric(event.target.value) || event.target.value == "" ){
            let ab = event.target.value
            console.log('true',ab.length)
            console.log((ab))
            this.setState({
                op_hour: event.target.value
            })
        }
        else{
            let ab = event.target.value
            console.log((ab.length))
        }        
    }
    
    handleCar = async (event) => {
        this.lessCondition(event, 'cars');
        if(isNumeric(event.target.value) || event.target.value == "" ){
            this.setState({
                cars: event.target.value
            })
        }
        else{
            console.log(isNumeric(event.target.value))
        }
    }
    handleWatt = async (event) => {
        this.lessCondition(event, 'watt');
        if(isNumeric(event.target.value) || event.target.value == "" ){
            this.setState({
                watt: event.target.value
            })
        }
        else{
            console.log(isNumeric(event.target.value))
        }
    }
    handleTime = async (event) => {
        this.lessCondition(event, 'time');
        if(isNumeric(event.target.value) || event.target.value == "" ){
            this.setState({
                time: event.target.value
            })
        }
        else{
            console.log(isNumeric(event.target.value))
        }
    }
    handleLight = async (event) => {
        this.lessCondition(event, 'light');
        if(isNumeric(event.target.value) || event.target.value == "" ){
            this.setState({
                light: event.target.value
            })
        }
        else{
            console.log(isNumeric(event.target.value))
        }
    }
    handleTotal = async () =>{

        let car_per_light = this.state.cars/this.state.light
        let t_on_time_per_light = 2*2*this.state.op_hour/this.state.time
        let time_per_light = ((t_on_time_per_light/60)*car_per_light)
        let ener_light = (this.state.watt/1000)*time_per_light
        let ener_all_light = ener_light*this.state.light
        let ener_month = ener_all_light*30

       let per_ener_light = (this.state.watt/1000)*this.state.op_hour
       let per_ener_all_light = per_ener_light*this.state.light
       let per_monthly_ener = per_ener_all_light*30

       let total = (((per_monthly_ener-ener_month)/per_monthly_ener)*100).toFixed(2)
        
       this.setState({
            bg_cal:{
                car_per_light,
                t_on_time_per_light,
                time_per_light,
                ener_light,
                ener_all_light,
                ener_month,
                p_spot:1,
            },
            bg_cal_Per:{
                per_ener_light, 
                per_ener_all_light,
                per_monthly_ener,
            },
            total
        })
        
        console.log('ye bhi he',per_ener_light)        
        console.log('ye bhi he',per_ener_all_light)        
        console.log('ye bhi he',per_monthly_ener)        
    }
     render(){
         console.log(this.state)
        return(
            <div className="container dropshadow rounded-1 my-5 pb-3">
            <div className="row p-3">
                <h2 className="col-12 parkinghead text-center gradient-primary2 p-3 text-light rounded-1">Parking Lot Energy Savings Calculator</h2>
            </div>
            <div className="row border mx-1 rounded">
                <div className="col m-3 p-0 d-flex justify-content-center">
                {/* fontFamily: "'Orbitron', sans-serif", */}
                    <h2 className="col-12 parkingscreen text-center px-5 py-4 rounded-1 mb-4" style={{backgroundColor: 'rgb(50,50,50)', color: 'rgb(180,200,255)', boxShadow: 'inset 0 0 10px black'}}>{
                        this.state.total == null || this.state.total < 0 || isNaN(this.state.total) ? 0: this.state.total}% Savings</h2>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-group row m-0">
                        <label className="col col-form-label text-md-right" htmlFor="op_hr"><h5>Operational time</h5></label>
                        <div className="col-md-7 d-flex">
                            <input type="number" className="form-control" id="op_hr" aria-describedby="op_help" placeholder="Operational Time" value= {this.state.op_hour == null? '' : this.state.op_hour} onChange={this.handleOP} onBlur={(e) => {this.blurValid(e,'op_hour')}} min="0.1"/>
                            <b style={{padding: '10px 0 10px 10px',width:'80px'}}>(Hours)</b>
                            {/* <small id="op_help" className="form-text text-muted">type in numeric value!</small> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-group row m-0">
                        <label className="col col-form-label text-md-right" htmlFor="no_car"><h5>Number of cars per day</h5></label>
                        <div className="col-md-7 d-flex">
                            <input type="number" className="form-control" id="no_car" aria-describedby="car_help" placeholder="No. Of Cars" value= {this.state.cars == null? '' : this.state.cars} onChange={this.handleCar} onBlur={(e) => {this.blurValid(e,'cars')}} min="1"/>
                            <b style={{padding: '10px 0 10px 10px',width:'80px'}}>(Cars)</b>
                            {/* <small id="car_help" className="form-text text-muted">type in numeric value!</small> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-group row m-0">
                        <label className="col col-form-label text-md-right" htmlFor="no_light"><h5>Number of parking spots</h5></label>
                        <div className="col-md-7 d-flex">
                            <input type="number" className="form-control" id="no_light" aria-describedby="light_help" placeholder="No. Of Lights" value= {this.state.light == null? '' : this.state.light} onChange={this.handleLight} onBlur={(e) => {this.blurValid(e,'light')}} min="1"/>
                            <b style={{padding: '10px 0 10px 10px',width:'80px'}}>(Spots)</b>
                            {/* <small id="light_help" className="form-text text-muted">type in numeric value!</small> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-group row m-0">
                        <label className="col col-form-label text-md-right" htmlFor="watt_light"><h5>Power of each light</h5></label>
                        <div className="col-md-7 d-flex">
                            <input type="number" className="form-control" id="watt_light" aria-describedby="watt_help" placeholder="Power Per Light" value= {this.state.watt == null? '' : this.state.watt} onChange={this.handleWatt} onBlur={(e) => {this.blurValid(e,'watt')}} min="0.1"/>
                            <b style={{padding: '10px 0 10px 10px',width:'80px'}}>(Watts)</b>
                            {/* <small id="watt_help" className="form-text text-muted">type in numeric value!</small> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-group row m-0">
                        <label className="col col-form-label text-md-right" htmlFor="hour"><h5>Average time a car stays parked</h5></label>
                        <div className="col-md-7 d-flex">
                            <input type="number" className="form-control" id="hour" aria-describedby="hour_help" placeholder="Average Time" value= {this.state.time == null? '' : this.state.time} onChange={this.handleTime} onBlur={(e) => {this.blurValid(e,'time')}} min="0.1"/>
                            <b style={{padding: '10px 0 10px 10px',width:'80px'}}>(Hours)</b>
                            {/* <small id="hour_help" className="form-text text-muted">type in numeric value!</small> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="form-group row m-0">
                        <div className="col">
                            <p className="text-danger text-md-right my-2" style={{fontFamily:'arial'}}><b><i>Note: 1 Light per parking spot</i></b></p>
                        </div>
                        <div className="col-md-7 d-flex">
                            <button id="submit" className="btn gradient-primary3" onClick={() => { this.handleTotal()}}>Calculate</button>
                            {/* <small id="hour_help" className="form-text text-muted">type in numeric value!</small> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
        )
    }
}


export default Calculator;