import React from "react";
import { Link } from "react-router-dom";

// Images

import logo from "../../images/logo.png";
import KWU from "../../images/KWU.png";
import PW from "../../images/PW.png";

// Tab

import HeaderTab from "./tab";

const Header = () => {
  var locationName = window.location.href.split("/");
  locationName = locationName[locationName.length - 1];
  return (
    <header className="header_area position-sticky dropshadow">
      <div className="main_menu" style={{ backgroundColor: "white" }}>
        <nav className="navbar navbar-expand-lg navbar-light bg-light w-100">
          <div className="row m-0 align-items-center justify-content-between w-100" id="navbar">
            <a
              className="col navbar-brand logo_h"
              href="https://www.linked-things.com"
            >
              <img
                src={logo}
                style={{ height: "50px" }}
                alt="logo"
              />
            </a>
            <div className="col d-flex justify-content-md-end justify-content-around align-items-center">
              <img
                src={KWU}
                style={{ height: "50px" }}
                alt="logo"
              />
              <img
                src={PW}
                style={{ height: "50px" }}
                alt="logo"
              />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
