import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Maps from "./maps/maps";
import RequestInfo from "./strip/request-info";
import AqiTable from "./tables/aqiTable";
import { getAqiDevices } from "../shared/services/events";
import { getAqiLevels } from "../shared/services/levels";
import Description from "./aq-desc/description";
import DecriptionBelow from "./aq-desc/desc-below";
// import Header from "../components/header/header";
// import Footer from "../components/footer";
import MinMaxBar from "./graphs/barGraph";
import Loader from "../components/loader1";
import _ from "lodash";
import { Row, Col } from "reactstrap";

let levelId = "",
  locationTitleCase = "",
  mapCoordinates;
class AqContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aqiDevices: null,
      dustDevices: null,
      filterHub: null,
    };
    levelId = "";
    locationTitleCase = "";
  }
  titleCase = (str) => {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  componentDidMount() {
    var locationName = this.props.match.params.location;
    this.aqiLevelClick(locationName);
  }

  async aqiLevelClick(location) {
    this.setState({
      aqiDevices: null,
      dustDevices: null,
      filterHub: null,
    });
    locationTitleCase = this.titleCase(location);
    if (location === "karachi") {
      mapCoordinates = { lat: 24.891465, lng: 67.081024 };
    } else if (location === "lahore") {
      mapCoordinates = { lat: 31.576354, lng: 73.461685 };
    } else if (location === "islamabad") {
      mapCoordinates = { lat: 33.722596, lng: 73.098958 };
    } else if (location === "peshawar") {
      mapCoordinates = { lat: 34.015802, lng: 71.589388 };
      locationTitleCase = this.titleCase("Islamabad");
    }

    document.getElementById("root_title").innerHTML =
      "Linked Things - " + locationTitleCase + " Air Quality";

    let aqiDevices = await getAqiDevices(
      locationTitleCase,
      "AQI"
    );
    let dustDevicesArr = [];
    let aqiDevicesArr = [];

    let dustSplit = null;
    if (aqiDevices !== "error") {
      aqiDevices.sort((a, b) => (a.event.value < b.event.value ? 1 : -1));
      let dustDevices = await getAqiDevices(
        locationTitleCase,
        "Dust"
      );
      aqiDevices.map((device) => {
        dustDevices.map((dust) => {
          if (device.hubId === dust.hubId) {
            dustSplit = dust.event.deviceId.split("_");
            if (dustSplit[2] == "Dust" || dustSplit[2] == "Dust2.5") {
              device["dust"] = dust.event.deviceId;
            }
          }
        });
      });
      aqiDevices.map((aqi) => {
        if (!_.get(aqi, "level[0].metadata.indoor")) {
          aqiDevicesArr.push(aqi);
        }
      });
      aqiDevices = aqiDevicesArr;
      dustDevices.map((dust) => {
        if (!_.get(dust, "level[0].metadata.indoor")) {
          dustDevicesArr.push(dust);
        }
      });
      dustDevices = dustDevicesArr;
      if (dustDevices !== "error") {
        this.setState({
          aqiDevices,
          dustDevices,
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <Header /> */}
        <Description />
        <div className="container p-md-0 mt-5">
          <div
            className="col-12 p-2 dropshadow"
            style={{ borderRadius: "50px" }}
          >
            <a
              href="#/airquality/karachi"
              onClick={() => this.aqiLevelClick("karachi")}
              className={
                locationTitleCase === "Karachi"
                  ? "btn p-2 px-sm-4 px-3 mr-2 gradient-primary1 text-light"
                  : "btn p-2 px-sm-4 px-3 mr-2 btn-light-grey"
              }
              style={{ borderRadius: "50px" }}
            >
              Karachi
            </a>
            {/* <a
              href="#/airquality/lahore"
              onClick={() => this.aqiLevelClick("lahore")}
              className={
                locationTitleCase === "Lahore"
                  ? "btn p-2 px-sm-4 px-3 mr-2 gradient-primary1 text-light"
                  : "btn p-2 px-sm-4 px-3 mr-2 btn-light-grey"
              }
              style={{ borderRadius: "50px" }}
            >
              Lahore
            </a> */}
            <a
              href="#/airquality/peshawar"
              onClick={() => this.aqiLevelClick("peshawar")}
              className={
                locationTitleCase === "Islamabad"
                // locationTitleCase === "Peshawar"
                  ? "btn p-2 px-sm-4 px-3 mr-2 gradient-primary1 text-light"
                  : "btn p-2 px-sm-4 px-3 mr-2 btn-light-grey"
              }
              style={{ borderRadius: "50px" }}
            >
              Peshawar
            </a>
          </div>
        </div>
        <div className="container my-0 mt-2">
          <div className="row p-1 rounded mb-5 dropshadow">
            <div className="col-12 col-md-8 mt-3 p-2">
              {this.state.aqiDevices ? (
                <Maps
                  aqiDevices={this.state.aqiDevices}
                  key={locationTitleCase}
                  location={mapCoordinates}
                />
              ) : (
                <Loader />
              )}
            </div>

            <div className="col-12 col-md-4 mt-3 p-2">
              {this.state.aqiDevices && this.state.dustDevices ? (
                <AqiTable
                  aqiDevices={this.state.aqiDevices}
                  dustDevices={this.state.dustDevices}
                />
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
        <div className="container my-0">
          {locationTitleCase !== "" ? (
            <MinMaxBar
              _id={
                locationTitleCase === "Peshawar"
                  ? "Islamabad"
                  : locationTitleCase
              }
              type="Level"
              key={locationTitleCase}
              title={"Daily Max and Min AQI " + locationTitleCase}
            />
          ) : (
            <Loader />
          )}
        </div>
        <div
          id="location_graphs"
          className="col-12 p-0 gradient-primary2 mt-5 mb-3 py-4 text-light text-center"
          style={{ fontSize: "18px" }}
        >
          <b>Max and Min AQI by Location</b>
        </div>
        <div className="container mt-0 mb-5">
          <div className="row">
            {this.state.aqiDevices ? (
              this.state.aqiDevices.map((device) => (
                <Col
                  key={device._id}
                  sm="12"
                  md="6"
                  lg="4"
                  xs="12"
                  xl="4"
                  className="m-0 p-sm-3 p-0"
                >
                  <div className="dropshadow m-3">
                    <MinMaxBar
                      _id={device._id}
                      type="Device"
                      title={device.location}
                    />
                  </div>
                </Col>
              ))
            ) : (
              <Loader />
            )}
          </div>
        </div>

        <RequestInfo />
        <div className="container py-5">
          <DecriptionBelow />
        </div>
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}

export default withRouter(AqContainer);
